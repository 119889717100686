import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "./styles";

function DetailTable({ invoiceData }) {
  const [data, setData] = useState({ items: [], subAmount: 0, gst: 0 });
  useEffect(() => {
    const mData = [];
    if (invoiceData.dafyCommission) {
      mData.push({
        name: "Platform Fee",
        gstPer: invoiceData.fareType.companyGST / 2,
        gst: invoiceData.companyGst / 200,
        amount: invoiceData.dafyCommission / 100,
        hsn: invoiceData?.fareType.platformFeeHsn,
      });
    }
    if (invoiceData.driverCommission) {
      mData.push({
        name: "Driver Fee",
        gstPer: invoiceData.fareType.driverCommissionGST / 2,
        gst: invoiceData.driverCommissionGst / 200,
        amount: invoiceData.driverCommission / 100,
        hsn: invoiceData?.fareType.driverFeeHsn || "",
      });
    }
    if (invoiceData.insured) {
      mData.push({
        name: "Insurance Fee",
        gstPer: invoiceData.fareType.secureGST / 2,
        gst: invoiceData.secureAmountGst / 200,
        amount: invoiceData.secureAmount / 100,
        hsn: invoiceData?.fareType.secureFeeHsn,
      });
    }
    const gst = mData.reduce((total, item) => total + item.gst, 0);
    const subAmount = mData.reduce((total, item) => total + item.amount, 0);

    setData({ items: mData, subAmount, gst });
  }, [invoiceData]);

  return (
    <TableContainer
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
        borderRightWidth: 0,
        borderRadius: "0",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={styles.tableHeadCell} colSpan={3}>
              Description
            </TableCell>
            <TableCell align="center" sx={styles.tableHeadCell}>
              HSN
            </TableCell>
            <TableCell align="center" sx={styles.tableHeadCell}>
              Qty
            </TableCell>
            <TableCell align="center" colSpan={2} sx={styles.tableHeadCell}>
              Tax %
            </TableCell>
            <TableCell align="center" colSpan={2} sx={styles.tableHeadCell}>
              Tax Amount
            </TableCell>
            <TableCell align="center" colSpan={2} sx={styles.tableHeadCell}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items?.map((item) => (
            <React.Fragment key={item.name}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ ...styles.bodyCell, borderBottom: 0 }}
                  colSpan={3}
                >
                  {item.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.bodyCell, borderBottom: 0 }}
                >
                  {item?.hsn || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ ...styles.bodyCell, borderBottom: 0 }}
                >
                  {"1"}
                </TableCell>
                <TableCell align="right" sx={styles.bodyCell} colSpan={2}>
                  {`SGST - ${item.gstPer || 0}%`}
                </TableCell>
                <TableCell align="right" sx={styles.bodyCell} colSpan={2}>
                  {`₹${item.gst.toFixed(2)}`}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ ...styles.bodyCell, borderBottom: 0 }}
                  colSpan={2}
                >
                  {`\u20B9${item.amount.toFixed(2)}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  sx={styles.bodyCell}
                  colSpan={3}
                ></TableCell>
                <TableCell align="center" sx={styles.bodyCell}></TableCell>
                <TableCell align="center" sx={styles.bodyCell}></TableCell>
                <TableCell align="right" sx={styles.bodyCell} colSpan={2}>
                  {`CGST - ${item.gstPer || 0}%`}
                </TableCell>
                <TableCell align="right" sx={styles.bodyCell} colSpan={2}>
                  {`₹${item.gst.toFixed(2)}`}
                </TableCell>
                <TableCell
                  align="center"
                  sx={styles.bodyCell}
                  colSpan={2}
                ></TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell colSpan={7} sx={styles.gstCell2} />
            <TableCell
              colSpan={2}
              align="right"
              sx={styles.gstCell(true, true, true)}
            >
              Sub Total
            </TableCell>
            <TableCell align="right" sx={styles.gstCell(true, false, true)}>
              ₹ {data?.subAmount.toFixed(2) || 0}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} sx={styles.gstCell2} />
            <TableCell
              colSpan={2}
              align="right"
              sx={styles.gstCell(true, true, true)}
            >
              {"CGST"}
            </TableCell>
            <TableCell align="right" sx={styles.gstCell(true, false, true)}>
              ₹ {data?.gst?.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={7} sx={styles.gstCell2} />
            <TableCell
              colSpan={2}
              align="right"
              sx={styles.gstCell(true, true)}
            >
              {"SGST"}
            </TableCell>
            <TableCell align="right" sx={styles.gstCell(true, false)}>
              ₹ {data?.gst?.toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailTable;

import React from "react";
import "./index.css";

import { Box, Grid, Typography, useTheme, Link } from "@mui/material";

import Logo from "../../Assets/driver (2).jpg";

import MainLayOut from "../../Components/MainLayOut";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  AboutDescription,
  SocialMediaIcons,
  roadmapData,
} from "../../Constants/About";


function About() {
  const theme = useTheme();
  return (
    <>
      <MainLayOut
        title={"About us"}
        subtitle={"Experience Convenience with DAFY Online"}
        id={"About-Us"}
        bgrColor={theme.palette.blue[500]}
        subTitleColor={theme.palette.grey[200]}
        compHeight={"auto"}
      >
        <Box className="about-body" width={"100%"} height={"100%"}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ padding: "1rem" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                className="a-grid-left"
                sx={{
                  width: "90%",
                  height: "100%",
                  borderRadius: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Logo}
                  alt="LOGO"
                  style={{
                    width: "85%",
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                variant="h6"
                color={"primary"}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xs: "1.4rem",
                    sm: "1.4rem",
                    md: "1.8rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                }}
              >
                DAFY Online Pvt. Ltd.
              </Typography>
              <Typography
                variant="subtitle1"
                color={theme.palette.grey[400]}
                key={"1"}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xs: "0.6rem",
                    sm: ".6rem",
                    md: ".8rem",
                    lg: "0.9rem",
                    xl: "1rem",
                  },
                  letterSpacing: {
                    xs: "2px",
                    sm: "3px",
                    md: "4px",
                    lg: "5px",
                    xl: "5px",
                  },
                }}
              >
                Your Personalized Transportation Solution
              </Typography>
              <List
                sx={{
                  width: "88%",
                  height: "100%",
                  padding: "10px",
                  color: theme.palette.grey[200],
                }}
                dense={false}
              >
                {AboutDescription.map((item) => (
                  <ListItem>
                    <ListItemIcon>
                      <RadioButtonCheckedOutlinedIcon
                        color="primary"
                        sx={{
                          fontSize: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "1.3rem",
                            lg: "1.5rem",
                            xl: "1.5rem",
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item} secondary={null} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Box
            className="box1"
            id="Team"
            sx={{
              width: "100%",
              padding: "3rem 0",
            }}
          >
            <Typography
              variant="subtitle1"
              color={"primary"}
              textAlign={"center"}
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.6rem",
                  lg: "1.8rem",
                  xl: "2rem",
                },
              }}
            >
              MEET THE DEDICATED MINDS BEHIND OUR SUCCESS
            </Typography>

            
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop
                pagination={{
                  clickable: true,
                  PaginationOptions: {
                    type: "progressbar",
                  },
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  
                }}
                modules={[Autoplay, Pagination, EffectFade]}
                className="mySwiper"
              >
                {roadmapData.map((element, index) => {
                  return (
                    <SwiperSlide className="sliders">
                      <Box
                        item
                        className="info-card"
                        sx={{
                          width: {
                            xs: "90%",
                            sm: "90%",
                            md: "85%",
                            lg: "300px",
                            xl: "300px",
                          },
                          height: "200px",
                          border: "1px solid yellow",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <Box
                          className="info-image"
                          sx={{
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                            border: "1px solid yellow",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            top: "-30%",
                          }}
                          backgroundColor={theme.palette.primary[300]}
                        >
                          <img
                            src={element.logoSrc}
                            alt="info-Logo"
                            style={{
                              width: "110px",
                              height: "110px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <Box
                          className="info-content"
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                          sx={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "15%",
                          }}
                        >
                          <Typography
                            variant="h6"
                            color={theme.palette.grey[100]}
                            textAlign={"center"}
                            fontSize={"1.1rem"}
                            letterSpacing={"3px"}
                          >
                            {element.title}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color={theme.palette.grey[300]}
                            textAlign={"center"}
                            borderRadius={"5px"}
                            fontSize={"0.7rem"}
                            letterSpacing={"2px"}
                          >
                            {element.info}
                          </Typography>
                          <Box
                            className="social-media"
                            display={"flex"}
                            justifyContent={"space-evenly"}
                            sx={{ width: "70%" }}
                          >
                            {SocialMediaIcons.map((iconItem) => {
                              return (
                                <Link
                                  href={element.social[iconItem.name]}
                                  px={"6px"}
                                  target={"_blank"}
                                  paddingTop={"1rem"}
                                  color={theme.palette.grey[100]}
                                >
                                  {iconItem.icon}
                                </Link>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            
          </Box>
        </Box>
      </MainLayOut>
    </>
  );
}

export default About;

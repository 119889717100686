import React from "react";

import { Box } from "@mui/material";
import NOT_FOUND from "../../Assets/NotFoundPage.png";

function PageNotFound() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={NOT_FOUND}
        alt="notFound"
        style={{ width: "90%", height: "90%", objectFit: "contain" }}
      />
    </Box>
  );
}

export default PageNotFound;

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Fab,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation } from "react-router-dom";
import { convertToWords } from "../../Components/utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Loader from "./Components/Loader";
import DafyDetails from "./Components/PreviewComponents/DafyDetails";
import BillDetails from "./Components/PreviewComponents/BillDetails";
import DetailTable from "./Components/PreviewComponents/DetailTable";
import TaxInvoicePDF from "./Components/TaxInvoicePDF";
import SIGNATURE from "../../Assets/invoiceSignature.png";
import { styles } from "./TaxInvoicePDF/styles";

const A4Container = styled(Box)(({ theme }) => ({
  width: "210mm",
  backgroundColor: "#fff",
  padding: "1rem",
  fontFamily: "Arial, sans-serif",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("md")]: {
    width: "70%",
    padding: "0.8rem",
  },

  [theme.breakpoints.down("sm")]: {
    width: "95%",
    padding: "0.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    padding: "0.5rem",
  },
  alignSelf: "center",
}));

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery("(max-width: 1024px)");

  const getTripData = useCallback(async () => {
    try {
      const pathSegments = location.pathname.split("/");
      const tripID = pathSegments[pathSegments.length - 1];
      if (tripID) {
        setLoading(true);
        const encodedTripID = encodeURIComponent(tripID);
        const url = `https://staging.api.dafy.in/invoice?tripID=${encodedTripID}`;
        const response = await axios.get(url);
        if (response?.data && response?.data?.data) {
          setInvoiceData(response?.data?.data);
        } else {
          setError("No data received from server");
        }
      } else {
        setError("Invalid URL parameters");
      }
    } catch (error) {
      setError("Error fetching data");
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    getTripData();
  }, [getTripData]);

  // if (!error) {
  //   return <InvalidData />;
  // }

  return loading ? (
    <Loader />
  ) : (
    <Stack
      sx={{
        padding: { xs: "0.7rem", lg: "1rem" },
        backgroundColor: "beige",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          marginBottom: "1rem",
          fontSize: { xs: "1.5rem", md: "2rem" },
        }}
      >
        Trip Invoice
      </Typography>

      <A4Container>
        <DafyDetails title={"Tax Invoice"} />
        <BillDetails invoiceData={invoiceData} />
        {/* <TripDetails invoiceData={invoiceData} /> */}
        <DetailTable invoiceData={invoiceData} />
        <Box
          display="flex"
          flexDirection="row"
          sx={{ border: "1px solid black" }}
        >
          <Box
            flex={1}
            sx={{
              borderRight: "1px solid #000",
              paddingX: { xs: "0.2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "12px" },
                marginVertical: "2px",
                fontFamily: "sans-serif",

                fontWeight: 700,
                paddingTop: 1,
              }}
            >
              Total Amount In Words :
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "12px" },
                marginVertical: "2px",
                fontFamily: "sans-serif",
                paddingBottom: 1,
                lineHeight: { xs: "12px" },
              }}
            >
              {convertToWords(invoiceData?.amount / 100)}
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="space-between"
            sx={{ paddingRight: 2, paddingTop: 2 }}
          >
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "12px" },
                fontWeight: 700,
                marginLeft: 2,
                fontFamily: "sans-serif",
              }}
            >
              Total Amount
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 700,
                marginLeft: 2,
                fontFamily: "san-serif",
              }}
            >
              ₹ {(invoiceData?.amount / 100 || 0)?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Stack
          direction={"row"}
          sx={{ border: "1px solid #000", borderTop: 0 }}
        >
          <Box
            sx={{
              borderRight: "1px solid #000",
              paddingX: { xs: "0.2rem" },
              flex: 1,
            }}
          ></Box>
          <Box
            sx={{
              paddingRight: 2,
              paddingTop: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={styles.logo}>
              <img
                src={SIGNATURE}
                alt="sign"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "10px", md: "12px" },
                fontWeight: 700,
                marginLeft: 2,
                fontFamily: "sans-serif",
              }}
            >
              Authorised Signatory
            </Typography>
          </Box>
        </Stack>
      </A4Container>
      <PDFDownloadLink
        document={<TaxInvoicePDF invoiceData={invoiceData} />}
        fileName={`Dafy_Invoice_${moment().format("DD-MM-YYYY")}`}
        style={{
          width: "fit-content",
          position: "absolute",
          bottom: isMobileOrTablet ? "5rem" : "3rem",
          right: isMobileOrTablet ? "2rem" : "5rem",
          alignSelf: "flex-end",
          textDecoration: "none",
        }}
      >
        {({ loading }) => {
          if (!loading) {
            return (
              <Fab
                variant="extended"
                sx={{ fontWeight: 700, bottom: 0 }}
                color="primary"
              >
                <DownloadIcon sx={{ mr: !isMobileOrTablet ? 1 : 0 }} />
                {!isMobileOrTablet && "Download"}
              </Fab>
            );
          }
          return null;
        }}
      </PDFDownloadLink>
    </Stack>
  );
};

export default Invoice;

import React from "react";
import { Page, View, Document } from "@react-pdf/renderer";
import DetailTablePDF from "../TaxInvoicePDF/DetailTablePDF";
import { styles } from "../TaxInvoicePDF/styles";
import DafyDetailsPDF from "../TaxInvoicePDF/DafyDetailsPDF";
import InvoiceDetailsPDF from "../TaxInvoicePDF/InvoiceDetailsPDF";
import FareSummaryPDF from "../TaxInvoicePDF/FareSummaryPDF";

function TaxInvoicePDF({ invoiceData, title = "TAX INVOICE" }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ border: "1px solid black", flex: 1 }}>
          <DafyDetailsPDF title={"Tax Invoice"} />
          <InvoiceDetailsPDF invoiceData={invoiceData} />
          <DetailTablePDF invoiceData={invoiceData} />
          <FareSummaryPDF invoiceData={invoiceData} />
        </View>
      </Page>
    </Document>
  );
}

export default TaxInvoicePDF;

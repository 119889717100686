export const styles = {
  companyInfo: {
    fontSize: { xs: "1rem", xl: "1.2rem", lg: "1.25rem" },
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  titleText: {
    textTransform: "uppercase",
    fontFamily: "sans-serif",
    fontSize: { xs: "1.1rem", xl: "1.5rem", lg: "1.6rem" },
  },
  addressText: {
    fontSize: { xs: "10px" },
    fontFamily: "sans-serif",
    fontWeight: 400,
  },
  textHeader: {
    fontSize: { xs: "0.8rem", lg: "1rem" },
    fontWeight: "bold",
    fontFamily: "sans-serif",
    flex: 1,
  },
  textBody: {
    fontSize: { xs: "10px", md: "12px" },
    marginVertical: "2px",
    fontFamily: "sans-serif",
  },
  logo: {
    width: { xs: 40, md: 50, lg: 60 },
    height: { xs: 40, md: 50, lg: 60 },
    objectFit: "contain",
    fontFamily: "sans-serif",
  },
  tableCell: {
    fontSize: { xs: "10px", md: "12px" }, // smaller font on mobile
    fontFamily: "sans-serif",
    borderRight: "1px solid #000",
    margin: 0,
    padding: { xs: "0 0.25rem", md: "0 0.5rem" }, // adjust padding for mobile and desktop
    height: { xs: 8, md: 10 },
  },
  tableHeadCell: {
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    margin: 0,
    padding: { xs: "8px", md: "10px", xl: "12px" },
    lineHeight: { xs: "8px", md: "10px" }, // smaller line height on mobile
    fontFamily: "sans-serif",
    fontWeight: "bold",
    fontSize: { xs: "10px", md: "12px" }, // adjust font size
  },
  bodyCell: {
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    margin: 0,
    padding: { xs: "3px", md: "6px", xl: "10px" },
    lineHeight: { xs: "10px", md: "12px" },
    fontFamily: "sans-serif",
    fontSize: { xs: "10px", md: "12px" },
  },
  bodyCell2: {
    border: "0px solid #fff",
    borderBottom: "1px solid #000",
    margin: 0,
    lineHeight: { xs: "6px", md: "8px" }, // smaller line height on mobile
    fontFamily: "sans-serif",
    fontSize: { xs: "10px", md: "12px" }, // adjust font size
  },
  gstCell: (border, bold, btmBorder) => {
    return {
      border: "0px solid #fff",
      borderRight: border && "1px solid #000",
      lineHeight: { xs: "6px", md: "8px" },
      margin: 0,
      fontFamily: "sans-serif",
      fontWeight: bold ? "bold" : "normal",
      borderBottom: btmBorder && "1px solid #000",
      fontSize: { xs: "10px", md: "12px" },
      padding: { xs: "4px", md: "6px", xl: "10px" },
    };
  },
  gstCell2: {
    border: "0px solid #fff",
    borderRight: "1px solid #000",
    lineHeight: { xs: "6px", md: "8px" }, // adjust line height
    margin: 0,

    fontSize: { xs: "10px", md: "12px" }, // adjust font size
  },
};

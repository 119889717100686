import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./styles";
const LOGO = require("../../../../Assets/dafy-logo.png");

function DafyDetails({ title = "Tax Invoice" }) {
  return (
    <Box
      display="flex"
      flexDirection={"row"}
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
        padding: { xs: 1, md: 2 },
      }}
    >
      <Box flex={1}>
        <Box sx={styles.logo}>
          <img
            src={LOGO}
            alt="Loading..."
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
        <Typography sx={styles.companyInfo}>Dafy Online Pvt Limited</Typography>
        <Typography sx={styles.addressText}>
          1, 34/192 C, Melthara Lane
        </Typography>
        <Typography sx={styles.addressText}>Padivattom, Edappally</Typography>
        <Typography sx={styles.addressText}>ERNAKULAM Kerala 682024</Typography>
        <Typography sx={styles.addressText}>India</Typography>
      </Box>
      <Box
        flex={1}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          sx={styles.titleText}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default DafyDetails;

import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQ_CONTENT_DAFY_PARTNER } from "../../../Constants/Support";

function DafyPartnerSupport() {
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: "1.2rem", textAlign: "center" }}
        gutterBottom
      >
        Top Questions Answered
      </Typography>
      {FAQ_CONTENT_DAFY_PARTNER.map((item, index) => {
        return (
          <Accordion key={item.key}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: "600" }}
            >
              {`${index + 1}. ${item?.question}`}
            </AccordionSummary>
            <AccordionDetails sx={{ mx: "1.2rem" }}>
              {item?.desc}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export default DafyPartnerSupport;

export const styles = {
  grid: {
    height: "500px",
    padding: "1.5rem",
    display: "flex",
    position: "relative",
    marginHorizontal: "20px",
    justifyContent: "center",
  },

  title: {
    fontSize: {
      xs: "0.9rem",
      sm: "0.9rem",
      md: "0.9rem",
      lg: "1.2rem",
      xl: "1.5rem",
    },
    fontWeight: "600",
    textAlign: "center",
    marginTop: "2.6rem",
    letterSpacing:{
      xs: "1px",
      sm: "2px",
      md: "0px",
      lg: "3px",
      xl: "4px",
    }
  },
};

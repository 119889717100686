import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import Logo from "../../Assets/dafy-logo.png";
import { CANCELLATION_AND_REFUND } from "../../Constants/CancellationAndRefund";

function CancellationAndRefundPolicy() {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "120px", height: "120px", ObjectFit: "contain" }}
        />
      </Box>
      <Typography
        color="secondary"
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "1.4rem",
            md: "2rem",
            xl: "3rem",
            textTransform: "uppercase",
          },
        }}
        gutterBottom
      >
        Cancellation and Refund Policy
      </Typography>
      <Box
        className="container"
        sx={{
          width: "90%",
          color: "black",
          paddingBottom: "1rem",
        }}
      >
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ fontSize: { xs: "0.8rem", md: "1rem" }, textAlign: "left" }}
        >
          DAFY ONLINE PRIVATE LIMITED BELIEVES IN HELPING ITS CUSTOMERS AS FAR
          AS POSSIBLE, AND HAS THEREFORE A LIBERAL CANCELLATION POLICY. UNDER
          THIS POLICY :
        </Typography>
        <Box sx={{ paddingX: { xs: "1rem" } }}>
          {CANCELLATION_AND_REFUND?.map((item, index) => (
            <>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bolder" }}
                gutterBottom
              >{`${index + 1} . ${item?.title}`}</Typography>
              <Typography
                variant="body1"
                sx={{ marginLeft: "2rem", marginBottom: "1rem" }}
              >{`${item?.description}`}</Typography>
            </>
          ))}
        </Box>
      </Box>
    </Paper>
  );
}

export default CancellationAndRefundPolicy;

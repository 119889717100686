import React from "react";

import { Paper, Box, Typography, Link, Grid, Stack } from "@mui/material";
import { DateRange } from "@mui/icons-material";
import Logo from "../../Assets/dafy-logo.png";

import styles from "./style.js";
// Importing Icons

import FooterItem from "../../Components/FooterItem";
import { useTheme } from "@emotion/react";
import { ContactItems, QuickLinks, SocialMedia } from "../../Constants/Footer";

export default function Footer() {
  const theme = useTheme();
  return (
    <Paper sx={styles.body}>
      <Typography
        variant="h6"
        textAlign={"center"}
        paddingTop={"2rem"}
        sx={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "1.8rem",
            lg: "2rem",
            xl: "2rem",
          },
        }}
      >
        DAFY Online Pvt Ltd
      </Typography>

      <Grid
        sx={styles.boxContainer}
        container
        spacing={1}
        paddingTop={{
          xs: "0px",
          sm: "6px",
          md: "1rem",
          lg: "1.5rem",
          xl: "2rem",
        }}
      >
        <Grid
          sx={styles.boxLogoContainer}
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          xl={3}
        >
          <img
            src={Logo}
            alt="Logo.."
            style={{ width: "100px", objectFit: "contain" }}
          />
          <Box
            display={"flex"}
            justifyContent={{
              xs: "space-evenly",
              sm: "space-evenly",
              md: "space-evenly",
              lg: "space-evenly",
              xl: "space-evenly",
            }}
            width={{
              xs: "100%",
              sm: "100%",
              md: "auto",
              lg: "auto",
              xl: "auto",
            }}
          >
            {SocialMedia.map((item) => (
              <Link
                href={item.link}
                target="_blank"
                rel="noreferrer"
                color={"secondary"}
                px={"6px"}
              >
                {item.logo}
              </Link>
            ))}
          </Box>
          <Stack alignItems={"center"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link
                href="/Terms&Condition"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                style={{
                  fontSize: "12px",
                  color: "blue",
                  textDecorationColor: "blue",
                }}
              >
                Terms And Condition
              </Link>
              <Box
                sx={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "2px",
                  backgroundColor: "#000",
                  margin: "5px",
                }}
              ></Box>
              <Link
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                style={{
                  fontSize: "12px",
                  color: "blue",
                  textDecorationColor: "blue",
                }}
              >
                Privacy Policy
              </Link>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link
                href="/Cancellation&RefundPolicy"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                style={{
                  fontSize: "12px",
                  color: "blue",
                  textDecorationColor: "blue",
                }}
              >
                Cancellation & Refund
              </Link>
              <Box
                sx={{
                  width: "4px",
                  height: "4px",
                  borderRadius: "2px",
                  backgroundColor: "#000",
                  margin: "5px",
                }}
              ></Box>
              <Link
                href="/support"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                style={{
                  fontSize: "12px",
                  color: "blue",
                  textDecorationColor: "blue",
                }}
              >
                Support
              </Link>
            </Box>
          </Stack>
        </Grid>
        <Grid sx={styles.boxItems} item xs={6} sm={12} md={6} lg={3} xl={3}>
          <Typography
            variant="subtitle1"
            sx={{ letterSpacing: { xs: "2px", xl: "5px" } }}
          >
            BUSINESS TIME
          </Typography>
          <FooterItem
            item={{
              id: "1",
              logo: <DateRange />,
              link: "24 / 7 Service",
            }}
          />
          {/* <FooterItem
            item={{
              id: "2",
              logo: <TimeToLeave />,
              link: "Any Time",
            }}
          /> */}
        </Grid>
        <Grid
          sx={styles.boxItems}
          display={"flex"}
          flexDirection={"column"}
          item
          xs={6}
          sm={12}
          md={6}
          lg={3}
          xl={3}
        >
          <Typography
            variant="subtitle1"
            sx={{ letterSpacing: { xs: "2px", xl: "5px" } }}
          >
            QUICK LINKS
          </Typography>
          {QuickLinks.map((item) => (
            <Link href={`#${item?.link}`} underline="none" color="secondary">
              <FooterItem item={item} />
            </Link>
          ))}
        </Grid>
        <Grid sx={styles.boxItems} item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Typography variant="subtitle1" textAlign={"center"}>
            CONTACT
          </Typography>
          {ContactItems.map((item) => (
            <FooterItem item={item} />
          ))}
        </Grid>
      </Grid>
      <Box
        sx={styles.copyRightsContainer}
        backgroundColor={theme.palette.grey[200]}
      >
        <Typography
          variant="subtitle1"
          color={"secondary"}
          textAlign={"center"}
        >
          ©2024 All Right Reserved.
        </Typography>
      </Box>
    </Paper>
  );
}

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 0,
    padding: "8px", // Adjust padding according to your design
  },
  textBody: {
    fontSize: "10px",
  },
  boldText: {
    fontWeight: "bold",
  },
  flexRow: {
    flexDirection: "row",
    marginVertical: "4px", // Adjust margin between rows
  },
  flexItem: {
    fontSize: "10px",
  },
  marginLeft: {
    marginLeft: "16px",
  },
  paddingTop: {
    paddingTop: "8px",
  },
});
function DriverDetailPDF({ invoiceData }) {
  return (
    <View style={styles.container}>
      <Text style={styles.textBody}>
        You rode with{" "}
        <Text style={styles.boldText}>{invoiceData?.driver?.name}</Text>
      </Text>
      <View style={styles.flexRow}>
        <Text style={[styles.flexItem, { textTransform: "uppercase" }]}>
          {invoiceData?.vehicle?.name}
        </Text>
        <Text style={[styles.flexItem, styles.marginLeft]}>
          {(invoiceData?.totalDistance / 1000).toFixed(2)} km
        </Text>
      </View>
      <View style={styles.paddingTop}>
        <Text style={styles.textBody}>
          {`- Start Time : ${moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "trip_started"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}`}
        </Text>
        <Text style={styles.textBody}>
          {`- End Time : ${moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "trip_completed"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}`}
        </Text>
      </View>
    </View>
  );
}

export default DriverDetailPDF;

import { Font } from "@react-pdf/renderer";

export const styles = {
  logo: {
    width: 60,
    height: 60,
    objectFit: "cover",
  },
  companyInfo: {
    fontSize: { xs: "0.8rem", xl: "1.2rem", lg: "1.25rem" },

    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  titleText: {
    textTransform: "uppercase",
    fontFamily: "sans-serif",
    fontSize: { xs: "1rem", xl: "1.5rem", lg: "1.6rem" },
  },
  addressText: {
    fontSize: { xs: "10px", md: "12px" },
    fontFamily: "sans-serif",
    fontWeight: 400,
  },
  textHeader: {
    fontSize: { xs: "0.8rem", lg: "1rem" },
    fontWeight: "bold",
    fontFamily: "sans-serif",
    flex: 1,
  },
  textBody: {
    fontSize: "12px",
    marginVertical: "2px",
    fontFamily: "sans-serif",
  },
  logo: {
    width: { xs: 40, md: 50, lg: 60 },
    height: { xs: 40, md: 50, lg: 60 },
    objectFit: "contain",
    fontFamily: "sans-serif",
  },
  tableCell: {
    fontSize: "12px",
    fontFamily: "sans-serif",
    borderRight: "1px solid #000",
    margin: 0,
    padding: "0 0.5rem",
    height: 10,
  },
  tableHeadCell: {
    borderBottom: "1px solid #000",
    borderRight: "1px solid #000",
    margin: 0,
    lineHeight: "8px",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  bodyCell: {
    margin: 0,
    lineHeight: { xs: "6px" },
    fontFamily: "sans-serif",
  },
  bodyCell2: {
    margin: 0,
    lineHeight: 0,
    fontFamily: "sans-serif",
  },
  gstCell: (border, bold, btmBorder) => {
    return {
      border: "0px solid #fff",
      borderRight: border && "1px solid #000",
      lineHeight: 0,
      margin: 0,
      fontFamily: "sans-serif",
      fontWeight: bold ? "bold" : "none",
      borderBottom: btmBorder && "1px solid #000",
    };
  },
  gstCell2: {
    border: "0px solid #fff",
    borderRight: "1px solid #000",
    lineHeight: 0,
    margin: 0,
  },
};

import React from 'react'
import { Paper, Typography,Box } from '@mui/material'

import Logo from "../../Assets/dafy-logo.png"

function TermsAndCondition() {
  return (
    <Paper sx={{display:'flex',alignItems:"center",flexDirection:"column",width:"100%"}}>
      <Box display={'flex'} justifyContent={'center'}>
        <img src={Logo} alt='Logo' style={{width:"120px",height:"120px",ObjectFit:"contain"}}/>
      </Box>
      <Typography   color='secondary' textAlign={'center'} sx={{fontSize:{xs:"1.4rem",md:"2rem",xl:"3.5rem"}}}>
          Terms And Condition
        </Typography>
        <Box
    className="container"
    style={{
      width:"80%",
      color: "black"
    }}
  >
    {/*---------------- Terms & Condition  Start------------*/}
    {/* <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "center"
      }}
    >
      <span
        style={{
          fontSize: 16,
          lineHeight: "107%",
          fontFamily: '"Times New Roman", Times, serif'
        }}
      >
        <strong>TERMS &amp; CONDITIONS</strong>
      </span>
    </p> */}
    {/* <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 25,
        fontFamily: "Calibri, sans-serif",
        textAlign: "center"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p> */}
    
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 25,
        fontFamily: "Calibri, sans-serif",
        textAlign: "center"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        These terms and conditions of use (‘’Terms of Use’’) of the
        <b> DAFY ONLINE</b> website and mobile app, is a legal agreement
        ("Agreement") between<b> DAFY ONLINE</b> Private Limited (“DAFY
        ONLINE”), a private limited company registered under the laws of India
        having its registered office at 34/192C, Melthara lane, Padivattom,
        Edappally, Ernakulam, Kerala – 682024, and yourself/yourselves. &nbsp;If
        you continue to browse the website and/or use the mobile application you
        are agreeing to comply with and be bound by the Terms of Use.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        If you do not agree with the Terms of Use, please do not access and use
        this Website / Application (as hereinafter defined).
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 19,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <strong>DEFINITIONS:</strong>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;For the purposes of the Terms of Use and Privacy Policy:
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        “DAFY ONLINE”, “we”, “our” and “us” shall mean<b> DAFY ONLINE</b>{" "}
        Private Limited;
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 16,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        “You”, yourself / yourselves, and “you're” shall mean a User, who meets
        the eligibility criteria set out below;
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        “Application” shall mean the application supplied by<b> DAFY ONLINE</b>{" "}
        and downloaded and installed by you on your single mobile device
        (smartphone);
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        “Driver” or ‘’Drivers’’ shall mean the driver engaged by you by using
        our Website/Application;
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        &nbsp;“Service” or “Services” shall mean the service of providing
        short-term private Driver solutions via the Application/Website;
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        ‘’Website’’ shall mean the website
        <a href="http://www.dafy.in." style={{ color: "blue" }}>
          <u> www.dafy.in.</u>
        </a>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;These Terms of Use may be modified from time to time in our sole
        discretion. It is your responsibility to review these Terms and
        Conditions from time to time. If you continue to use the Service after
        notice of change has been intimated or published on our
        Website/Application, you thereby provide your consent to the changed
        practices on the same terms hereof. For this reason, we encourage you to
        review these Terms of Use each time you access and use the
        Website/Application. Most content and some of the features on the
        Website/Application are made available to Users free of charge. However,
        <b> DAFY ONLINE</b> reserves the right to terminate access to certain
        areas or features of the Website/Application at any time for any reason,
        with or without notice. If you are not agreeable to these Terms of Use,
        you may not use the Services, the Website, or the Application.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;<strong>SERVICES DESCRIPTION:</strong>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE provides a technology-based service which enables the hiring
        of drivers by commuters/ passengers for a point to point pick up and
        drop off Service, within city limits and outside city limits, through
        the Application in mobile telecommunications devices and through the
        Website. The Service is designed to offer you information and a means of
        obtaining short-term private driver solutions to coordinate
        point-to-point and round-trip private driver services, at your request.
        As a User, you authorize<b> DAFY ONLINE</b> to operate your vehicle and
        make decisions on your behalf during a period of time designated by you.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <strong>USE OF SERVICE, APPLICATION AND WEBSITE:</strong>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The Application and the Website allows you to send a request for Service
        to a Driver. The GPS receiver, which should be installed on the mobile
        device (smart phone) on which you have downloaded the Application or the
        Website, shall detect your location and shall send your location
        information to the relevant Driver.<b> DAFY ONLINE</b> has sole and
        complete discretion to accept or reject each request for providing the
        Service.<b> DAFY ONLINE</b> also has sole and complete discretion over
        whether to use the Application / Website to receive the leads generated
        through the Application / Website. If<b> DAFY ONLINE</b> accepts a
        request, the Application / Website notifies you and provides information
        regarding the Driver - including his name and the ability to contact the
        Driver by telephone or a message. The Application / Website also allow
        you to view the Driver’s progress towards the pick-up point, in real
        time.
      </span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li>
        <span style={{}}>
          DAFY ONLINE shall undertake commercially reasonable efforts to bring
          you into contact with a Driver in order to avail the Services, subject
          to the availability of Drivers in or around your location at the
          moment of your request for the Services.
        </span>
      </li>
      <li>
        <span style={{}}>
          DAFY ONLINE itself does not provide transportation services. It is up
          to the User to offer transportation.<b> DAFY ONLINE</b> only acts as
          intermediary between the Driver and you.&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          The provision of the Services by the Driver to you is, therefore,
          subject to the agreement (to be) entered into between the Driver and
          you.&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          DAFY ONLINE shall under no circumstance be a party to such
          agreement.&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          DAFY ONLINE disclaims any and all liability in respect of the Drivers
          including any claims of employment or any vicarious liability arising
          out of the Service or otherwise.
        </span>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <br />
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <strong>USER ELIGIBILITY AND AGREEMENT:</strong>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        User means any individual or business entity/organization that legally
        operates in India or in other countries, and uses and has the right to
        use the Services provided by<b> DAFY ONLINE</b>. Our Services are
        available only to those individuals or entities who can execute legally
        binding contracts under the applicable law. Therefore, a User must not
        be a minor as per Indian Law; i.e. User(s) must be at least 18 years of
        age to be eligible to use our Services.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE advises its Users that while accessing the
        Website/Application, they must follow/abide by the applicable
        laws.&nbsp;
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE may, in its sole discretion, refuse the Service to anyone at
        any time.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        This Agreement applies to all Services offered on the
        Website/Application, collectively with any additional terms and
        condition that may be applicable.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{ fontWeight:'bold'}}>REGISTRATION:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;To use the Services, you have to be registered and provide your
        name, contact number, email address and other details. Please see our
        Privacy Policy and practices to know more about how your personal
        information would be used.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify",
        fontWeight:'bold'
      }}
    >
      <span style={{}}>REPRESENTATIONS AND WARRANTIES:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <br />
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;As a precondition to your use of the Services, you represent and
        warrant that:
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The information you provide to<b> DAFY ONLINE</b> is accurate and
        complete.<b> DAFY ONLINE</b> service is only available for private cars
        for non-commercial purposes within the city limits as designated on our
        Website. Private cars for non-commercial purposes bear license plates
        with black lettering over white coloured background (Commercial vehicles
        have license plates with black lettering over yellow coloured
        background). You will ensure that<b> DAFY ONLINE</b> service is being
        utilized only for non-commercial purposes in a private car.
        <b> DAFY ONLINE</b> is entitled, at all times, to verify the information
        that you have provided and to refuse the Service or use of the
        Application / Website without providing reasons.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You will only access the Service using authorized means. You are
        responsible to check and ensure you download the correct Application for
        your device or the correct Website in your computer.<b> DAFY ONLINE</b>{" "}
        shall not be liable if you do not have a compatible mobile device or if
        you download the wrong version of the Application for your mobile device
        or Website for the computer.<b> DAFY ONLINE</b> reserves the right to
        terminate the Service and the use of the Application/ Website should you
        use the Service or Application with an incompatible or unauthorized
        device.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You have the legal right and authority to possess and operate the
        vehicle when engaging our Services and you confirm, represent and
        warrant that the said vehicle is in good operating condition and meets
        the industry safety standards and all applicable statutory requirements
        for a motor vehicle of its kind.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You will be solely responsible for any and all liability which results
        from or is alleged as a result of the condition of your vehicle, legal
        compliance, etc., including, but not limited to, personal injuries,
        death and property damages.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You will be solely responsible for the full functionality of your
        vehicle. If your vehicle fails to function (electrical, mechanical or
        other) in any way while the Services are being availed of by you, you
        will be responsible for all storage fees, roadside assistance, alternate
        transportation and repair of any kind and neither<b> DAFY ONLINE</b> nor
        the Driver shall be responsible in any manner whatsoever. You have the
        legal right to designate the Driver as your agent and delegate actual
        authority to the Driver to operate your vehicle and make decisions on
        your behalf for the purposes of providing Services offered through the
        <b> DAFY ONLINE</b> platform.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You are named or scheduled on the insurance policy covering the vehicle
        you use when engaging our Services.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You have a valid policy of liability insurance (in coverage amounts
        consistent with all applicable legal requirements) for the operation of
        your vehicle to cover any anticipated losses related to your
        participation in the Services or the operation of your vehicle by the
        Driver. In the event of a motor vehicle accident, you will be solely
        responsible for compliance with any applicable statutory or department
        of motor vehicles requirements and for all necessary contacts with your
        insurance provider. Other than any personal criminal liability attaching
        to the Driver you will be solely responsible for all consequences
        arising out of the use of the Service or the Driver. In any event
        <b> DAFY ONLINE</b> shall have no responsibility or liability on this
        account whatsoever.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You specifically authorize us to use, store or otherwise process your
        'Sensitive personal data or information’ (as such term is defined in
        Information Technology (Reasonable security practices and procedures and
        sensitive personal data or information) Rules, 2011) in order to provide
        the Services to you. Subject to applicable law all information provided
        to us by you shall be deemed to be our information to use as we desire.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You will obey all applicable laws related to the matters set forth
        herein, and will be solely responsible for any violations of the same.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>CANCELLATION POLICY:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <br />
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE drivers will wait no more than 15 minutes from the requested
        trip start time, before they start the trip timer.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You may cancel the booking within 30 minutes prior to the time of
        journey, without any cancellation charges for all Services. The customer
        is liable for paying Rs 150/- charge if the trip is cancelled
        thereafter.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <br />
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>PAYMENT:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;When a trip ends,<b> DAFY ONLINE</b> driver ends the trip on the
        <b> DAFY ONLINE</b> Companion app on his mobile device. It then
        calculates the total fare amount due from You and it appears on the
        <b> DAFY ONLINE</b> app on your mobile device. You may pay the amount
        via cash or a variety of online payment options available on the app
        including payment via the DAFY Wallet where You may have already
        pre-loaded funds. The usage of the<b> DAFY ONLINE</b> Wallet shall be
        subject to the terms and conditions of the third-party payment processor
        providing the mobile payment service on the App. Any payment related
        issue, except when such issue is due to an error or fault in the Site or
        Application, shall be resolved between You and the payment processor.
        <b> DAFY ONLINE</b> shall not be responsible for any unauthorized use of
        Your DAFY Wallet.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>LIMITATION OF LIABILITY:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The information, recommendations and/or Services provided to you on or
        through the Website/Application are for general information purposes
        only and do not constitute advice.<b> DAFY ONLINE</b> will take
        reasonable steps to keep the Website/Application and its contents
        correct and up to date but does not guarantee that the contents of the
        Website/Application are free of errors, defects, malware and viruses or
        that the Website/Application are correct, up to date and accurate.
      </span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE shall not be liable for any damages resulting from the use
          of, or inability to use, the Website/Application, including damages
          caused by malware, viruses or any incorrectness or incompleteness of
          the information on the Website/Application.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE shall further not be liable for damages resulting from the
          use of, or the inability to use, electronic means of communication
          with the Website/Application, including — but not limited to — damages
          resulting from failure or delay in delivery of electronic
          communications, interception or manipulation of electronic
          communications by third parties or by computer programs used for
          electronic communications and transmission of viruses.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          Without prejudice to the foregoing, and insofar as allowed under
          mandatory applicable law,<b> DAFY ONLINE</b>’s aggregate liability
          shall in no event exceed the equivalent of the amount for the payment
          of the Services.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          The quality of the Services requested through the use of the
          Application is entirely the responsibility of the Driver who
          ultimately provides such transportation services to you.
          <b> DAFY ONLINE</b> under no circumstance accepts liability in
          connection with and/or arising from the Services provided by the
          Driver or any acts, actions, behaviour, conduct, and/or negligence on
          the part of the Driver.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          We shall not be held liable for any failure or delay in performing
          Services where such failure arises as a result of any act or omission,
          which is outside our reasonable control such as unprecedented
          circumstances, overwhelming and unpreventable events caused directly
          and exclusively by forces of nature that can be neither anticipated,
          nor controlled, nor prevented by the exercise of prudence, diligence,
          and care, including but not limited to: war, riot, civil commotion;
          compliance with any law or governmental order, rule, regulation or
          direction and acts of third parties ( “Force Majeure” ).
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          If we have contracted to provide identical or similar Service to more
          than one User and are prevented from fully meeting our obligations to
          you by reason of an event of Force Majeure, we may decide at our
          absolute discretion which booking we will fulfil by providing the
          Service, and to what extent.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          We have taken all reasonable steps to prevent internet fraud and
          ensure any data collected from you is stored as securely and safely as
          possible. However, we shall not be held liable in the unlikely event
          of a breach in our secure computer servers or those of third parties
          other than as required under applicable law.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          In the event we have a reasonable belief that there exists an abuse of
          vouchers and/or discount codes or suspect an instance of fraud, we may
          cause the User to be blocked immediately and reserve the right to
          refuse future Service. Additionally, should there exist an abuse of
          vouchers or discount codes,<b> DAFY ONLINE</b> reserves the right to
          seek compensation from any and all such Users.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE does not represent or endorse the accuracy or reliability
          of any information, or advertisements (collectively, the "Content")
          contained on, distributed through, or linked, downloaded or accessed
          from or contained on the Website/Application, or the quality of any
          products, information or other materials displayed, or obtained by you
          as a result of an advertisement or any other information or offer in
          or in connection with the Service.
        </span>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        Offers are subject to<b> DAFY ONLINE</b>'s discretion and may be
        withdrawn at any time and without notice.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <b>INTELLECTUAL PROPERTY RIGHTS:</b>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE is the sole owner or lawful licensee of all the rights to
        the Website/Application and its content. Website/Application content
        means its design, layout, text, images, graphics, sound, video etc. The
        Website/Application content embodies trade secrets and intellectual
        property rights protected under worldwide <b> Copyright</b> and other
        laws. All title, ownership and intellectual property rights in the
        Website/Application and its content shall remain with<b> DAFY ONLINE</b>
        .
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        All rights, not otherwise claimed under this Agreement or in the Website
        /Application, are hereby reserved. The information contained in this
        Website/Application is intended, solely to provide general information
        for the personal use of the reader, who accepts full responsibility for
        its use.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        You may access the Website/Application, avail of the features,
        facilities and Services for your personal or internal requirements only.
        You are not entitled to duplicate, distribute, create derivative works
        of, display, or commercially exploit the Website/Application Content,
        features or facilities, directly or indirectly, without our prior
        written permission of<b> DAFY ONLINE</b>.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <b> Copyright</b>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        All content on this Website/Application is the <b> Copyright</b> of
        <b> DAFY ONLINE</b> except the third-party content and link to third
        party website on our Website/Application, if any.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        Systematic retrieval of<b> DAFY ONLINE</b> content to create or compile,
        directly or indirectly, a collection, compilation, database or directory
        (whether through robots, spiders, automatic devices or manual processes)
        without written permission from<b> DAFY ONLINE</b> is prohibited.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        In addition, use of the content for any purpose not expressly permitted
        in this Terms of Use is prohibited and may invite legal action. As a
        condition of your access to and use of Services, you agree that you will
        not use the Website/Application to infringe the intellectual property
        rights of others in any way.<b> DAFY ONLINE</b> reserves the right to
        terminate the account of a User upon any infringement of the rights of
        others in conjunction with use of the Service, or if<b> DAFY ONLINE</b>{" "}
        believes that User’s conduct is harmful to the interests of
        <b> DAFY ONLINE</b>, its affiliates, or other Users, or for any other
        reason in<b> DAFY ONLINE</b>'s sole discretion, with or without cause.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        <b> USER ACCOUNTS, OFFERS AND PROMOTIONS:</b>
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE reserves the right to collect User data including name,
        contact information and other details to facilitate Services or use of
        its platform to avail Services. All information collected from the User
        are on a bona fide basis. Misuse and misrepresentation of identity or
        contact details will lead to automatic termination of Services or the
        use of the platform, without prior notice to such Users.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        User accounts bearing contact number and email IDs are created and owned
        by<b> DAFY ONLINE</b>. Any promotional discounts and offers accumulated
        can be revoked without prior notice in the event of suspicious account
        activity or mala fide intent of the User.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        In the case where the system is unable to establish unique identity of a
        User against a valid mobile number or e-mail ID, the account shall be
        indefinitely suspended.<b> DAFY ONLINE</b> reserves the full discretion
        to suspend a user’s account in the above event and does not have the
        liability to share any account information whatsoever.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>LINKS TO THIRD PARTY SITES:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        Links to third party sites are provided by the Application / Website as
        a convenience to Users and<b> DAFY ONLINE</b> does not have any control
        over such sites i.e., content and resources provided by them.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE may allow User’s access to content, products or services
        offered by third parties through hyperlinks (in the form of word link,
        banners, channels or otherwise) to such third party's website. You are
        cautioned to read such sites' terms and conditions and/or privacy
        policies before using such sites in order to be aware of the terms and
        conditions of your use of such sites. The Users acknowledge that
        <b> DAFY ONLINE</b> has no control over such third party's site, does
        not monitor such sites, and<b> DAFY ONLINE</b> shall not be responsible
        or liable to anyone for such third-party site, or any content, products
        or services made available on such a site.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>TERMINATION:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        DAFY ONLINE reserves the right to deny access to particular Users to
        any/all of its Services without any prior notice/explanation in order to
        protect the interests of<b> DAFY ONLINE</b> and/or other Users to the
        Website/Application.<b> DAFY ONLINE</b> reserves the right to limit,
        deny or create different access to the Website/Application and its
        features with respect to different Users.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        We reserve the right to terminate your account or your access to the
        Website/Application immediately, with or without notice to you, and
        without liability:&nbsp;
      </span>
    </p>
    <ol style={{ listStyleType: "lower-roman" }}>
      <li>
        <span style={{}}>
          If you have violated any of the Terms of Use;&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          if you have furnished us with false or misleading information;&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          pursuant to requests by law enforcement or other government
          agencies;&nbsp;
        </span>
      </li>
      <li>
        <span style={{}}>
          in case of unexpected technical or security issues or problems;
        </span>
      </li>
      <li>
        <span style={{}}>
          in case of discontinuance or material modification to the Services (or
          any part thereof); and/or
        </span>
      </li>
      <li>
        <span style={{}}>
          in case of interference with the use of our Website/Application by
          others.
        </span>
      </li>
    </ol>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        In the event of termination by you or us, your account will be disabled
        and you will not be granted access to your account or any information or
        content contained in your account. You will not and not attempt to
        create another account for accessing and using the Website/Application
        without the written consent of<b> DAFY ONLINE</b>.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        This Terms of Use shall remain in full force and effect while you have
        an account with us. Even after termination of your account with us,
        certain provisions of this Terms of Use will remain in effect, including
        but not limited to, Intellectual Property Rights, Prohibited Uses and
        Indemnification. You agree that we will not be liable to you or any
        third party for taking any of these actions.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        Notwithstanding the termination of this Agreement, you shall continue to
        be bound by the terms of this Agreement in respect of your prior use of
        this Website/Application and all matters connected with, relating to or
        arising from such use.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>TERMS &amp; CONDITIONS FOR USE OF SERVICES:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;The following terms &amp; conditions shall apply to Users
        utilising the Services offered by<b> DAFY ONLINE</b>:
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The User shall pay the driver fare (as displayed in the
        <b> DAFY ONLINE</b> App) or as agreed to in the terms of use as listed
        on the Application / Website, parking charges, additional night
        surcharge (where applicable), one-way trips, toll charges and any fee or
        levy presently payable or hereinafter imposed by the law or required to
        be paid for availing of the Services.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The User agrees and accepts that the use of the Services provided by
        <b> DAFY ONLINE</b> is at the sole risk of the User, and further
        acknowledges that<b> DAFY ONLINE</b> disclaims all representations and
        warranties of any kind, whether express or implied. All Services are
        provided “AS IS”.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The<b> DAFY ONLINE</b> or the Driver has the right to refuse the Service
        in the following circumstances:
      </span>
    </p>
    <ol style={{ listStyleType: "lower-roman" }}>
      <li
        style={{
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        if the User is found to be in an intoxicated state or is found
        misbehaving with other passengers or the Driver, or is causing a
        nuisance;
      </li>
      <li
        style={{
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        if the User is misusing, soiling or damaging any of the devices
        (technical/non-technical) of the Driver; and
      </li>
      <li
        style={{
          fontFamily: '"Times New Roman", Times, serif',
          textAlign: "justify"
        }}
      >
        if the User asks the Driver to break any traffic/RTO/city police and/or
        government rules for any purpose The Driver has the right to refuse such
        a request by the User.
      </li>
    </ol>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        Without prejudice to the above,<b> DAFY ONLINE</b> makes no
        representation or warranty that:
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        the Services will meet the User’s requirements; and the Services will be
        uninterrupted, timely, secure, or error-free.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The information on this Website/Application is provided "AS IS" with no
        guarantee of completeness, accuracy, timeliness or of the results
        obtained from the use of this information, and without warranty of any
        kind, express or implied, including, but not limited to warranties of
        performance, merchantability and fitness for a particular purpose.
        Nothing herein shall to any extent substitute for the independent
        investigations and the sound technical and business judgment of the
        Users. In no event shall<b> DAFY ONLINE</b> be liable for any direct,
        indirect, incidental, punitive, or consequential damages of any kind
        whatsoever with respect to the Service. Users of this site must hereby
        acknowledge that any reliance upon any content shall be at their sole
        risk.
      </span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE is hereby authorized to use the location-based information
          provided by any of the telecommunication companies when the User uses
          the mobile phone to make a booking. The location-based information
          will be used only to facilitate and improve the probability of
          locating a Driver for the User.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE shall be entitled to disclose to any government body the
          particulars of the User in the possession, in its absolute discretion.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE shall be entitled at any time without giving any reason to
          terminate the booking of Drivers done by the User.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          If the User has any complaints in respect of the Services or the use
          of the Driver, the User has to inform<b> DAFY ONLINE</b> of the same
          in writing within 24 hours of using the Driver or the Services.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          DAFY ONLINE shall not be liable for any conduct of the Drivers.
          However,<b> DAFY ONLINE</b> encourages you to notify it of any
          complaints that you may have against any Driver or the Services.
        </span>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>INSURANCE AND DAMAGE TO PROPERTY:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        We do not procure insurance for, nor are we responsible for, damage or
        loss of any personal belongings or intellectual property whilst
        providing the Services. These include tapes, records, discs or other
        similar audio visual or data electronic devices, any speed measuring
        equipment within the vehicle or any other accessories of any kind. It is
        your responsibility to ensure that you remove any personal belongings
        from the car before and after each &nbsp;service arranged through
        <b> DAFY ONLINE</b>.
      </span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>INDEMNITY:</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        The User shall defend, indemnify and hold,<b> DAFY ONLINE</b>, its
        affiliates, its licensors, and each of their officers, directors, other
        users, employees, attorneys and agents, harmless, from and against any
        and all claims, costs, damages, losses, liabilities and expenses
        (including attorneys' fees and costs) arising out of or in connection
        with the:
      </span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          violation or breach of the Terms of Use or any applicable law or
          regulation, whether or not referenced herein;
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          violation of any rights of any third party, including the Driver via
          the Application and or the Website; and
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          use or misuse of the Application/Website or Service.
        </span>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <h4
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>MISCELLANEOUS :</span>
    </h4>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;&nbsp;</span>
    </p>
    <ul style={{ listStyleType: "disc" }}>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          The information contained in this Website/Application is for general
          information purposes only. The information is provided by
          <b> DAFY ONLINE</b> and while we endeavour to keep the information up
          to date and correct, we make no representations or warranties of any
          kind, express or implied, about the completeness, accuracy,
          reliability, suitability or availability with respect to the
          Website/Application or the information, products, services, or related
          graphics contained on the Website/Application for any purpose. Any
          reliance you place on such information is, therefore, strictly at your
          own risk.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          In no event will we be liable for any loss or damage, including
          without limitation, indirect or consequential loss or damage, or any
          loss or damage whatsoever arising from loss of data or profits arising
          out of, or in connection with, the use of this Website/Application or
          Service.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          Every effort is made to keep the Website/Application up and running
          smoothly. However,<b> DAFY ONLINE</b> takes no responsibility for, and
          will not be liable for, the Website/Application being unavailable due
          to technical issues beyond our control.
        </span>
      </li>
      <li
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "8pt",
          lineHeight: "107%",
          fontSize: 15,
          fontFamily: "Calibri, sans-serif",
          textAlign: "justify"
        }}
      >
        <span style={{}}>
          The above-mentioned Terms of Use and the Privacy Policy constitute the
          entire agreement between the User and<b> DAFY ONLINE</b> with respect
          to access to and use of the Website/ Application and the Services
          offered by<b> DAFY ONLINE</b>, superseding any prior written or oral
          agreements in relation to the same subject matter herein.
        </span>
      </li>
    </ul>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <h4
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 17,
        fontWeight:'bold',
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>JURISDICTION :</span>
    </h4>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>&nbsp;</span>
    </p>
    <p
      style={{
        margin: "0cm 0cm 8pt",
        lineHeight: "107%",
        fontSize: 15,
        fontFamily: "Calibri, sans-serif",
        textAlign: "justify"
      }}
    >
      <span style={{}}>
        &nbsp;The courts of Ernakulam, Kerala, India shall have the sole and
        exclusive jurisdiction in respect of any matters arising from the use of
        the Services offered by<b> DAFY ONLINE</b> or the Terms of Use or any
        arrangement between<b> DAFY ONLINE</b> and the User.
      </span>
    </p>
    {/*---------- Terms & Condition End  ---------------*/}
  </Box>
        
      
       
    </Paper>
  )
}

export default TermsAndCondition
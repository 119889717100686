import { Backdrop } from "@mui/material";
import React from "react";
const LOGO = require("../../../Assets/dafy-logo.png");

function Loader() {
  return (
    <Backdrop
      sx={{ background: "beige", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <img
        src={LOGO}
        alt="Dafy.."
        style={{ width: "11rem", height: "11rem", objectFit: "contain" }}
      />
    </Backdrop>
  );
}

export default Loader;

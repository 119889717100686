import React from "react";
import "./index.css";

// Importing Constants
import { SERVICES_GRID } from "../../Constants/Services";

import { Box, Typography, Grid, useTheme } from "@mui/material";
import { styles } from "./style";
import MainLayOut from "../../Components/MainLayOut";

export default function Service() {
  const theme = useTheme();

  return (
    <>
      <MainLayOut
        title={"Our Services"}
        subtitle={"What We're Offering"}
        subTitleColor={theme.palette.grey[100]}
        bgrColor={theme.palette.blue[500]}
        id={"Service"}
      >
        <Grid
          container
          rowGap={"8rem"}
          px={"1rem"}
          paddingBottom={{
            xs: "10rem",
            sm: "12rem",
            md: "12rem",
            lg: "12rem",
            xl: "12rem",
          }}
        >
          {SERVICES_GRID.map((item, index) => (
            <Grid
              
              item
              xs={item.xs}
              sm={item.sm}
              md={item.md}
              lg={item.lg}
              xl={item.xl}
              sx={styles?.grid}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "75%",
                    md: "90%",
                    lg: "100%",
                    xl: "100%",
                  },
                  height: {
                    xs: "85%",
                    sm: "85%",
                    md: "70%",
                    lg: "100%",
                    xl: "100%",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <img
                  src={item?.background}
                  alt="Single Trip"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  bottom: {
                    xs: "-10%",
                    sm: "-20%",
                    md: "-10%",
                    lg: "-30%",
                    xl: "-30%",
                  },
                  width: {
                    xs: "70%",
                    sm: "50%",
                    md: "60%",
                    lg: "80%",
                    xl: "80%",
                  },
                  height: {
                    xs: "43%",
                    sm: "50%",
                    md: "50%",
                    lg: "45%",
                    xl: "45%",
                  },
                  borderRadius: 2,

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                backgroundColor={theme?.palette?.background?.light}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    bottom: { lg: "0px", xl: "-1rem" },
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    textTransform={"uppercase"}
                    sx={styles?.title}
                    textAlign={"center"}
                  >
                    {item?.title}
                  </Typography>
                  {item.description.map((text) => (
                    <Typography
                      variant="body1"
                      color={theme.palette.secondary[500]}
                      px="10px"
                      fontSize={{
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "0.7rem",
                        lg: "0.7rem",
                        xl: "0.8rem",
                      }}
                    >
                      {`➤ ${text}`}
                    </Typography>
                  ))}
                </Box>

                <Box
                  sx={{
                    width: {
                      xs: "70px",
                      sm: "80px",
                      md: "70px",
                      lg: "90px",
                      xl: "90px",
                    },
                    height: {
                      xs: "70px",
                      sm: "80px",
                      md: "70px",
                      lg: "90px",
                      xl: "90px",
                    },
                    borderRadius: "50%",
                    position: "inherit",
                    top: { xs: "-20%", sm: "-18%", md: "-20%", lg: "", xl: "" },
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                  backgroundColor={theme?.palette?.primary[500]}
                >
                  <img
                    src={item.icon}
                    alt="Single Trip Logo"
                    style={{ height: "50%", objectFit: "contain" }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </MainLayOut>
    </>
  );
}

import React, { useState, useEffect } from "react";
import "./index.css";
import NavBar from "../NavBar";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/material";

function ScrollableNavBAr() {
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 140) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
    
      className={`navbar-box ${isMobile || scrolling ? "navbar-scroll" : ""}`}
      sx={{
        top: {
          sm:"120px",
          md:"134px",
          lg: "140px",
          xl: "140px",
        },
      }}
    >
      <NavBar scrolling={scrolling} />
    </Box>
  );
}

export default ScrollableNavBAr;

import { Font, Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";
import { convertToWords } from "../../../Components/utils";
import SIGNATURE from "../../../Assets/invoiceSignature.png";
import NatoFont from "../../../Assets/fonts/NotoSans-Regular.ttf";

Font.register({
  family: "customFont",
  src: NatoFont,
});

function FareSummaryPDF({ invoiceData }) {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid black",
        }}
      >
        <View style={styles.flexOne}>
          <Text
            style={[styles.textBody, { paddingLeft: 10 }]}
          >{`Total Amount In Words : 
              ${convertToWords(invoiceData?.amount / 100)}`}</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderLeft: "1px solid black",

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              marginVertical: "2px",
              fontWeight: 700,
              paddingLeft: 100,
            }}
          >
            Total Amount
          </Text>
          <Text
            style={[
              styles.textBody,
              { paddingRight: 5, fontFamily: "customFont" },
            ]}
          >
            ₹ {(invoiceData?.amount / 100 || 0).toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{
            flex: 1,
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
          }}
        ></View>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderBottom: "1px solid #000",
          }}
        >
          <Image
            src={SIGNATURE}
            alt={"Sign"}
            style={{ width: 60, height: 60, objectFit: "contain" }}
          />
          <Text
            style={{
              fontSize: "10px",
              fontWeight: 700,
            }}
          >
            Authorised Signatory
          </Text>
        </View>
      </View>
    </>
  );
}

export default FareSummaryPDF;

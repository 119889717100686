import React from "react";

import { Paper, Typography, Box } from "@mui/material";

import Logo from "../../Assets/dafy-logo.png";

function PrivacyAndPolicy() {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "120px", height: "120px", ObjectFit: "contain" }}
        />
      </Box>
      <Typography
        color="secondary"
        textAlign={"center"}
        sx={{
          fontSize: { xs: "1.4rem", md: "2rem", xl: "3.5rem" },
          textTransform: "uppercase",
        }}
      >
        Privacy Policy
      </Typography>
      <Box
        className="container"
        sx={{
          width: "80%",
          color: "black",
          padding: "1rem",
        }}
      >
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Interpretation and Definitions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Interpretation
        </Typography>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Definitions
        </Typography>
        <p>For the purposes of this Privacy Policy : </p>
        <p>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </p>
        <p>
          <strong>Affiliate</strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control"
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </p>
        <p>
          <strong>Application</strong> means the software program provided by
          the Company downloaded by You on any electronic device, named DAFY
        </p>
        <p>
          <strong>Company </strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to
          <strong> DAFY ONLINE PRIVATE LIMITED </strong>, # 34/192C, Melthara
          lane, Padivattom, Edappally, Cochin 682024, Kerala, India.
        </p>
        <p>
          <strong>Country</strong> refers to: Kerala, India
        </p>
        <p>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cell phone or a digital tablet.
        </p>
        <p>
          <strong>Personal Data </strong>
          is any information that relates to an identified or identifiable
          individual.
        </p>
        <p>
          <strong>Service</strong> refers to the Application.
        </p>
        <p>
          <strong>Service Provider </strong>
          means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analysing how the Service is used.
        </p>
        <p>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </p>
        <p>
          <strong>You</strong> mean the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Collecting and Using Your Personal Data
        </Typography>
        <Typography variant="body1">Types of Data Collected</Typography>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Personal Data
        </Typography>
        <p>
          While using Our Service, we may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>1. Email address</li>
          <li>2. First name and last name</li>
          <li>3. Phone number</li>
          <li>4. Address, State, Province, ZIP/Postal code, City</li>
          <li>5. Usage Data</li>
          <li>6. Location Information</li>
          <li>7. Transaction Information</li>
          <li>8. Driver Information</li>
        </ul>
        <br />
        <p>
          For drivers using our platform, we may collect additional information
          such as driver's license details, vehicle information, insurance
          details, and other relevant data.
        </p>
        <br />
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Usage Data
        </Typography>
        <p>Usage Data is collected automatically when using the Service.</p>
        <br />
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <br />
        <p>
          When You access the Service by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <br />
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Information Collected while Using the Application
        </Typography>
        <p>
          While using Our Application, in order to provide features of Our
          Application, we may collect, with Your prior permission:
        </p>
        <br />
        <ul>
          <li>
            1. <strong>Service Delivery : </strong> To connect users with
            drivers and facilitate the requested services.
          </li>
          <li>
            2. <strong>Communication : </strong>To send transaction
            notifications, updates, and customer support.
          </li>
          <li>
            3. <strong>Improvement : </strong> To enhance our services, analyse
            usage patterns, and gather feedback.
          </li>
          <li>
            4. <strong>Information regarding your location : </strong>
          </li>
        </ul>
        <Box sx={{ paddingX: "1rem" }}>
          <p>
            We use background location data to enhance the driver experience and
            ensure service efficiency. This data enables:
          </p>
          <ul>
            <li>
              <strong>• Accurate Navigation and Routing : </strong> Continuous
              location tracking helps provide optimal routes, avoiding traffic
              and reducing travel time.
            </li>
            <li>
              <strong>• Real-Time Ride Matching : </strong> We efficiently match
              drivers with nearby passengers, improving response times and ride
              availability.
            </li>
            <li>
              <strong>• Safety and Support : </strong>Background location
              ensures driver safety by allowing quick assistance in emergencies
              and providing accurate service records.
            </li>
            <li>
              <strong>• Performance Analytics : </strong> Analysing location
              data helps us optimize our service, identify high-demand areas,
              and improve overall performance.
            </li>
          </ul>
          <br />
          <p>
            Your background location data is securely stored and used solely for
            these purposes, in compliance with privacy regulations.
          </p>
        </Box>
        <ul>
          <li>
            5. <strong>Information </strong> from your Device's phone book
            (contacts list)
          </li>
          <li>
            6. <strong>Pictures and other information</strong> from your
            Device's camera and photo library
          </li>
        </ul>
        <br />
        <p>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </p>
        <br />
        <p>
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Use of Your Personal Data
        </Typography>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p>
          <strong>To provide and maintain our Service </strong>, including to
          monitor the usage of our Service.
        </p>
        <p>
          <strong>To manage Your Account : </strong>to manage Your registration
          as a user of the Service. The Personal Data You provide can give You
          access to different functionalities of the Service that are available
          to You as a registered user.
        </p>
        <p>
          <strong>For the performance of a contract : </strong> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </p>
        <p>
          <strong>To contact You : </strong> To contact You by email, telephone
          calls, SMS, or other equivalent forms of electronic communication,
          such as a mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </p>
        <p>
          <strong>To provide You </strong>with news , special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </p>
        <p>
          <strong>To manage Your requests : </strong>
          To attend and manage Your requests to Us.
        </p>
        <p>
          <strong>For business transfers : </strong>
          We may use Your information to evaluate or conduct a merger,
          divestiture, restructuring, reorganization, dissolution, or other sale
          or transfer of some or all of Our assets, whether as a going concern
          or as part of bankruptcy, liquidation, or similar proceeding, in which
          Personal Data held by Us about our Service users is among the assets
          transferred.
        </p>
        <p>
          <strong>For other purposes : </strong> We may use Your information for
          other purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Service, products, services, marketing and
          your experience.
        </p>
        <br />
        <p>
          We may share Your personal information in the following situations :
        </p>
        <br />
        <p>
          <strong>With Service Providers : </strong>
          We may share Your personal information with Service Providers to
          monitor and analyse the use of our Service, to contact You.
        </p>
        <br />
        <p>
          <strong>For business transfers : </strong>
          We may share or transfer Your personal information in connection with,
          or during negotiations of, any merger, sale of Company assets,
          financing, or acquisition of all or a portion of Our business to
          another company.
        </p>
        <br />
        <p>
          <strong>With Affiliates : </strong>
          We may share Your information with Our affiliates, in which case we
          will require those affiliates to honour this Privacy Policy.
          Affiliates include Our parent company and any other subsidiaries,
          joint venture partners or other companies that We control or that are
          under common control with Us.
        </p>
        <br />
        <p>
          <strong>With business partners : </strong>
          We may share Your information with Our business partners to offer You
          certain products, services or promotions.
        </p>
        <br />
        <p>
          <strong>With other users : </strong>
          when You share personal information or otherwise interact in the
          public areas with other users, such information may be viewed by all
          users and may be publicly distributed outside.
        </p>
        <br />
        <p>
          <strong>With Your consent : </strong> We may disclose Your personal
          information for any other purpose with Your consent.
        </p>
        <br />
        <p>
          <strong>Drivers : </strong>To fulfil ride requests.
        </p>
        <br />
        <p>
          <strong>Payment Processors : </strong> For transaction processing.
        </p>
        <br />
        <p>
          <strong>Legal Compliance : </strong>In response to a legal request or
          to comply with applicable laws.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Retention of Your Personal Data
        </Typography>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <br />
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Transfer of Your Personal Data
        </Typography>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <br />
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <br />
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Disclosure of Your Personal Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          Business Transactions
        </Typography>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Law enforcement
        </Typography>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Other legal requirements
        </Typography>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li> 1. Comply with a legal obligation</li>
          <li>2. Protect and defend the rights or property of the Company</li>
          <li>
            3. Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            4. Protect the personal safety of Users of the Service or the public
          </li>
          <li>5. Protect against legal liability</li>
        </ul>
        <br />
        <Typography variant="body1" gutterBottom>
          Security of Your Personal Data
        </Typography>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, we cannot guarantee
          its absolute security.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Children's Privacy
        </Typography>
        <p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, we take
          steps to remove that information from Our servers.
        </p>
        <br />
        <p>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, we may
          require Your parent's consent before We collect and use that
          information.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Links to Other Websites
        </Typography>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third-party link, you will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          Changes to this Privacy Policy
        </Typography>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <br />
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <br />
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Contact Us
        </Typography>
        <p>
          If you have any questions about this Privacy Policy, you can contact
          us:
        </p>
        <p>
          By email : <strong>contact@dafy.in</strong>
        </p>
      </Box>
    </Paper>
  );
}

export default PrivacyAndPolicy;

import React from "react";
import "./index.css";

// Importing Mui Elements

import { Box, Typography, Grid } from "@mui/material";

import { FEATURES_GRID } from "../../Constants/Features";
import MainLayOut from "../../Components/MainLayOut";


export default function Features() {
  return (
    <MainLayOut title={"Our Features"} subtitle={"Client Success Commitment"}>
      <Grid container columnSpacing={2} sx={{ paddingBottom: { xs: "3rem" } }}>
        {FEATURES_GRID?.map((item) => (
          <>
            <Grid
              item
              md={6}
              lg={6}
              xl={3}
              className="f-grid"
              borderRight={"1px dashed #a3a3a3"}
            >
              <Box className="f-grid-logo-cntd">
                <Box className="f-grid-img">
                  <img src={item?.logo} alt="Feature Grid icon" />
                </Box>
              </Box>
              <Box className="f-grid-cntd">
                <Typography
                  variant="subtitle2"
                  color={"secondary"}
                  textAlign={"center"}
                  sx={{ height: "2rem" }}
                 
                >
                  {item?.title}
                </Typography>
                <Typography
                
                  variant="body2"
                  color="secondary"
                  textAlign={"center"}
                  textTransform={"capitalize"}
                  sx={{ height: "100px" }}
                >
                  {item?.description}
                </Typography>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </MainLayOut>
  );
}

import React from "react";
import './index.css'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  EffectCoverflow,
  Navigation,
  Autoplay
} from "swiper/modules";



const CarouselBanner = [
  {
    id: "1",
    image: require("../../Assets/sliderBanner1.jpg"),
  },
  {
    id: "2",
    image: require("../../Assets/Slider2.1.jpg"),
  },
  {
    id: "3",
    image: require("../../Assets/Slider3.jpg"),
  },
];

const Carousel = () => {
  return (
    <Swiper
      
      swiper-navigation-disabled
      pagination={{ clickable: true }}
      autoplay={{ delay: 4000 }}
      spaceBetween={20}
      slidesPerView={1}
      loop={true}
      modules={[EffectCoverflow,  Navigation,Autoplay]}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        
      }}
    >
      {CarouselBanner.map((item) => (
        <SwiperSlide key={item.id}>
          <div className="carousel-item">
            <img src={item.image} alt="Slide 1" className="carousel-image" />
          </div>
        </SwiperSlide>
      ))}

      {/* Add more slides as needed */}
    </Swiper>
  );
};

export default Carousel;

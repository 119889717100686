export const FEATURES_GRID = [
  {
    id: 1,
    title: "Personalized\nService",
    description:
      "Our commitment lies in understanding our client’s unique requirements and interests, ensuring and providing customized service to meet all their expectations.",
    logo: require("../../Assets/Icons/service.png"),
  },
  {
    id: 2,
    title: "In-City\nRides",
    description:
      "Ensure that your drivers are familiar with the city's roads and traffic patterns. Optimize routing to minimize travel time and provide efficient and timely rides.",
    logo: require("../../Assets/Icons/cab.png"),
  },
  {
    id: 3,
    title: "Out Station Rides",
    description:"Driver service offers experienced, skilled designated drivers for long-distance travel with excellent customer service.",
    logo: require("../../Assets/Icons/Icons3.png"),
  },
   {
    id : 4,
    title : "Bar Pickup",
    description : "It enhances safety by preventing drunk driving accidents, allowing patrons to enjoy a worry-free night out.",
    logo :require('../../Assets/Icons/bar.png')
   }
];

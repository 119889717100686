import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

function DriverDetail({ invoiceData }) {
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
        padding: { xs: 1, md: 2 },
      }}
    >
      <Typography sx={{ fontSize: "12px", fontFamily: "sans-serif" }}>
        {`You rode with `}
        <span style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>
          {invoiceData?.driver?.name}
        </span>
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{ fontSize: "12px", fontFamily: "sans-serif" }}
        >{`${invoiceData?.vehicle?.name}`}</Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontFamily: "sans-serif",
            marginLeft: "1rem",
          }}
        >{`${(invoiceData?.totalDistance / 1000).toFixed(2)} km`}</Typography>
      </Box>
      <Box sx={{ paddingTop: "0.5rem" }}>
        <Typography sx={{ fontSize: "12px", fontFamily: "sans-serif" }}>
          {`⦿	 Start Time : ${moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "trip_started"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}`}
        </Typography>
        <Typography sx={{ fontSize: "12px", fontFamily: "sans-serif" }}>
          {`⦿	End Time : ${moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "trip_completed"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default DriverDetail;

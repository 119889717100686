import React, { useEffect } from "react";
import "./App.css";
// Importing Mui Elements
import { CssBaseline, ThemeProvider, Box } from "@mui/material";

import { Route, Routes } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import { theme } from "./theme";

import Service from "./Pages/Service";
import ScrollableNavBAr from "./Components/ScrollableNavBar";
import HomePage from "./Pages/Home";
import Contact from "./Pages/Contact";
import Testimonals from "./Pages/Testimonals";
import TermsAndCondition from "./Pages/Terms&Condition";
import Features from "./Pages/Features";
import Footer from "./Pages/Footer";
import ServiceFeaturing from "./Pages/Service/ServiceFeaturing";
import About from "./Pages/About";
import ForDrivers from "./Pages/ForDrivers";
import PrivacyAndPolicy from "./Pages/Terms&Condition/PrivacyAndPolicy";
import DriverTermsAndCondition from "./Pages/Terms&Condition/driver/driverTermsAndCondition";
import DriverPrivacyAndPolicy from "./Pages/Terms&Condition/driver/driverPrivacyAndPolicy";
import CancellationAndRefundPolicy from "./Pages/CancellationAndRefundPolicy";
import PageNotFound from "./Pages/PageNotFound";
import Support from "./Pages/Support";
import DeleteDriver from "./Pages/DeleteAccountPages/DeleteDriver.js";
import DeleteCustomer from "./Pages/DeleteAccountPages/DeleteCustomer.js";
import SecureBookingTermsAndCondition from "./Pages/Terms&Condition/SecureBooking/index.js";
import Invoice from "./Pages/Invoice/index.js";
import InvoiceFareBreakDown from "./Pages/InvoiceFareBreakDown/index.js";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        <Route
          path="/"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <ScrollableNavBAr />
              <HomePage />
              <ServiceFeaturing />
              <Service />
              <Features />
              <About />
              <ForDrivers />
              <Testimonals />
              <Contact />
              <Footer />
            </Box>
          }
        />
        <Route
          path="/Terms&Condition"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <TermsAndCondition />
            </Box>
          }
        />
        <Route
          path="secure-booking/Terms&Condition"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <SecureBookingTermsAndCondition />
            </Box>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <PrivacyAndPolicy />
            </Box>
          }
        />
        <Route
          path="/support"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <Support />
            </Box>
          }
        />
        <Route
          path="/driver/Terms&Condition"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <DriverTermsAndCondition />
            </Box>
          }
        />
        <Route
          path="/account/driver/delete"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <DeleteDriver />
            </Box>
          }
        />
        <Route
          path="/account/customer/delete"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <DeleteCustomer />
            </Box>
          }
        />
        <Route
          path="/Cancellation&RefundPolicy"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <CancellationAndRefundPolicy />
            </Box>
          }
        />
        <Route
          path="/driver/privacy-policy"
          element={
            <Box
              className="app"
              sx={{ width: "100%", height: "100%" }}
              position={"relative"}
            >
              <DriverPrivacyAndPolicy />
            </Box>
          }
        />
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/invoice/2/:id" element={<InvoiceFareBreakDown />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

export const CANCELLATION_AND_REFUND = [
  {
    id: 1,
    title: " Cancellation Upon Driver Arrival : ",
    description:
      "Cancellations will be disabled if the driver reaches the booking location. However, oursupport team will assist you if your travel plans change.",
  },
  {
    id: 2,
    title: "Assistance with Ride Cancellations and Rescheduling : ",
    description:
      "Our support team is available to help you cancel rides before or during the trip, or to assist with rescheduling. Cancellation charges may apply",
  },
  {
    id: 3,
    title: "Customer No-Show or Delayed Arrival : ",
    description:
      "If a customer cannot be reached or arrives late after the scheduled pickup time for a confirmed booking, and the driver has already arrived, cancellation charges will be applied.",
  },
  {
    id: 4,
    title: "Emergency Situations : ",
    description:
      "For complaints or emergency issues, customers can use the SOS button in the app or contact the registered support number.",
  },
  {
    id: 5,
    title: "Refund Processing : ",
    description:
      "In case of any refunds approved by DAFY Online Private Limited, it will take 6-8 days for the refund to be processed and credited to the customer’s account .",
  },
];

import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import "./index.css";
import MainLayOut from "../../Components/MainLayOut";

import quotes from "../../Assets/Icons/quote.png";
import { CUSTOMER_REVIEWS } from "../../Constants/Testimonials";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Testimonals() {
  const theme = useTheme();
  return (
    <MainLayOut
      title={"Testimonials"}
      subtitle={"WHAT CLIENTS SAY ABOUT OUR SERVICES!"}
      id={"Testimonials"}
      compHeight={"auto"}
    >
      <Box
        className="testimonials-body"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "column-reverse",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Box
          className="tm-left-cntd"
          width={{ xs: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }}
          height={{
            xs: "auto",
            sm: "fit-content",
            md: "100%",
            lg: "100%",
            xl: "100%",
          }}
        >
          <Box className="tm-carousel-content" key={"1"}>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              loop
              pagination={{
                clickable: true,
                PaginationOptions: {
                  type: "progressbar",
                },
              }}
              modules={[Autoplay, Pagination, EffectFade]}
              
            >
              {CUSTOMER_REVIEWS.map((item) => {
                return (
                  <SwiperSlide>
                    <Box className="carousel-header">
                      <Typography
                        variant="h6"
                        color={"secondary"}
                        textAlign={"center"}
                        textTransform={"uppercase"}
                        letterSpacing={"3px"}
                        sx={{
                          fontSize: {
                            xs: "1.6rem",
                            sm: "1.8rem",
                            md: "1.8rem",
                            lg: "2rem",
                            xl: "2rem",
                          },
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box className="carousel-body">
                      <img
                        src={quotes}
                        alt="Customer review"
                        style={{ width: "60px", height: "60px" }}
                      />

                      <Typography
                        variant="body1"
                        padding={"10px"}
                        paddingBottom={'3rem'}
                        textAlign={"left"}
                        paddingTop={0}
                        marginLeft={"10px"}
                      >
                        {item.review}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Box>
        <Box
          className="tm-right-cntd"
          backgroundColor={theme.palette.blue[500]}
          width={{ xs: "100%", sm: "100%", md: "100%", lg: "50%", xl: "50%" }}
          height={{
            xs: "70vmin",
            sm: "70vmin",
            md: "70vmin",
            lg: "70vmin",
            xl: "70vmin",
          }}
        >
          <Box
            width={"100%"}
            height={"100%"}
            position={"absolute"}
            top={"0"}
            backgroundColor={"rgba(0,0,0,0.7)"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          ></Box>
          <Typography
            width={"100%"}
            variant="subtitle1"
            color={"primary"}
            textAlign={"center"}
            zIndex={"1"}
            sx={{
              fontSize: {
                xs: "0.6rem",
                sm: ".6rem",
                md: ".8rem",
                lg: "0.9rem",
                xl: "1rem",
              },
              letterSpacing: {
                xs: "2px",
                sm: "3px",
                md: "4px",
                lg: "5px",
                xl: "5px",
              },
            }}
          >
            Discover the voices of satisfaction and success through our
            compelling testimonials
          </Typography>
        </Box>
      </Box>
    </MainLayOut>
  );
}

export default Testimonals;

import React from "react";
import "./index.css";

import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { theme, tokens } from "../../theme";
import logo from "../../Assets/dafy-logo.png";
import CallIcon from "@mui/icons-material/Call";
import Sliders from "../../Components/CarouselSlider";

function HomePage() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          backgroundColor={tokens?.background?.main}
          sx={{ width: "100%", height: "auto" }}
          id="Home"
        >
          <Box className="home-body" sx={{ overflowX: "hidden" }}>
            <Box className="home-content-highlighter"></Box>
            <Box className="home-logo-container">
              <Box
                className="home-logo-box"
                sx={{
                  position: "absolute",
                  top: { xs: "20%", sm: "-5%", md: "0", lg: "0", xl: "0" },
                }}
              >
                <img src={logo} alt="Logo"></img>
              </Box>
            </Box>
            <Box
              className="home-info-content"
              sx={{
                width: "100%",
                position: "absolute",
                zIndex: "2",

                top: { xs: "40%", sm: "40%", md: "40%", lg: "40%", xl: "40%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                color={tokens?.grey[100]}
                textTransform={"uppercase"}
                letterSpacing={"6px"}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xs: "1.1rem",
                    sm: "1.4rem",
                    md: "1.6rem",
                    lg: "1.8rem",
                    xl: "2rem",
                  },
                }}
              >
                welcome to <span style={{ color: "#fcd12a" }}>Dafy!</span>
              </Typography>
              <Typography
                data-aos="zoom-in"
                data-aos-duration="2000"
                variant="h2"
                color={tokens?.grey[100]}
                paddingBottom={{
                  xs: "1rem",
                  sm: "1.rem",
                  md: "0.9rem",
                  lg: "0.7rem",
                  xl: "0.7rem",
                }}
                sx={{
                  fontWeight: "900",
                  fontSize: {
                    xs: "3.6rem",
                    sm: "4rem",
                    md: "5rem",
                    lg: "5rem",
                    xl: "6rem",
                  },
                }}
                letterSpacing={{
                  xs: "2px",
                  sm: "3px",
                  md: "4px",
                  lg: "6px",
                  xl: "6px",
                }}
                textAlign={"center"}
              >
                BOOK YOUR <span style={{ color: "#fcd12a" }}>RIDE!</span>
              </Typography>
              <Typography
                variant="body1"
                color={tokens?.grey?.[100]}
                textTransform={"uppercase"}
                letterSpacing={{
                  xs: "2px",
                  sm: "3px",
                  md: "4px",
                  lg: "6px",
                  xl: "8px",
                }}
                fontWeight={600}
                textAlign={"center"}
                sx={{
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1.1rem",
                    xl: "1.2rem",
                  },
                }}
              >
                Most reliable and economical professional drivers on demand
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<CallIcon color="primary" />}
                  sx={{ marginTop: "1rem" }}
                >
                  <a href="tel:+91 7592933933" style={{ color: "inherit" }}>
                    Call Now
                  </a>
                </Button>
              </Box>
            </Box>
            <Sliders />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default HomePage;

import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQ_CONTENT_DAFY } from "../../../Constants/Support";
import { LabelImportant } from "@mui/icons-material";

function DafySupport() {
  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: "1.2rem", textAlign: "center" }}
        gutterBottom
      >
        Top Questions Answered
      </Typography>
      {FAQ_CONTENT_DAFY.map((item, index) => {
        return (
          <Accordion key={item.key} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: "600" }}
            >
              {`${index + 1}. ${item?.question}`}
            </AccordionSummary>
            <AccordionDetails sx={{ mx: "1.2rem" }}>
              {item?.desc}
              {item.subDesc &&
                item.subDesc.map((subItems) => {
                  return (
                    <List>
                      <ListItem dense>
                        <ListItemIcon>
                          <LabelImportant color="primary" />
                        </ListItemIcon>
                        <Typography>{subItems}</Typography>
                      </ListItem>
                    </List>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export default DafySupport;

import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import NatoFont from "../../../Assets/fonts/NotoSans-Regular.ttf";

Font.register({
  family: "customFont",
  src: NatoFont,
});
const styles = StyleSheet.create({
  container: {
    borderTopWidth: "1px",
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: "row",
    paddingVertical: 4,
  },
  cell: {
    flex: 1,
    padding: "6px",
    fontSize: "10px",
  },
  amountCell: {
    flex: 0.5,
    textAlign: "right",
    marginRight: "4px",
  },
});
function FareDetailPDF({ invoiceData }) {
  const [data, setData] = useState({ items: [], subAmount: 0, gst: 0 });

  useEffect(() => {
    const mData = [];
    if (invoiceData.fareBreakDown?.baseFare) {
      mData.push({
        name: "Base Fare",
        amount: (invoiceData.fareBreakDown.baseFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.distanceFare) {
      mData.push({
        name: "Distance Fare",
        amount: (invoiceData.fareBreakDown.distanceFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.timeFare) {
      mData.push({
        name: "Time Fare",
        amount: (invoiceData?.fareBreakDown?.timeFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.driverConvenienceFee) {
      mData.push({
        name: "Driver Convenience Fee",
        amount: (invoiceData?.fareBreakDown?.driverConvenienceFee || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.nightCharge) {
      mData.push({
        name: "Night Fare",
        amount: (invoiceData?.fareBreakDown?.nightCharge || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.discountAmount) {
      mData.push({
        name: "Discount",
        amount: (invoiceData?.fareBreakDown?.discountAmount || 0) / 100,
      });
    }

    const subAmount = mData.reduce((total, item) => total + item.amount, 0);

    setData({ items: mData, subAmount, gst: 0 }); // GST not calculated as in the original code
  }, [invoiceData]);

  return (
    <View style={styles.container}>
      {data.items.map((item, index) => (
        <View
          key={item.name}
          style={{
            ...styles.row,
            borderBottomWidth: data?.items?.length - 1 === index ? 1 : 0,
          }}
        >
          <Text style={styles.cell}>{item.name}</Text>
          <Text
            style={[
              styles.cell,
              styles.amountCell,
              { fontFamily: "customFont" },
            ]}
          >
            {item.amount ? `₹ ${item.amount.toFixed(2)}` : ""}
          </Text>
        </View>
      ))}
    </View>
  );
}

export default FareDetailPDF;

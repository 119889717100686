import { createTheme } from "@mui/material";

// Color design Tokens

export const tokens = {
  grey: {
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
  },
  primary: {
    // Yellow
    100: "#fef6d4",
    200: "#feedaa",
    300: "#fde37f",
    400: "#fdda55",
    500: "#fcd12a",
    600: "#caa722",
    700: "#977d19",
    800: "#655411",
    900: "#322a08",
  },

  secondary: {
    // Black
    100: "#cccccc",
    200: "#999999",
    300: "#666666",
    400: "#333333",
    500: "#000000",
    600: "#000000",
    700: "#000000",
    800: "#000000",
    900: "#000000",
  },
  blue: {
    // blue
    100: "#d2d7db",
    200: "#a6afb7",
    300: "#798693",
    400: "#4d5e6f",
    500: "#20364b",
    600: "#1a2b3c",
    700: "#13202d",
    800: "#0d161e",
    900: "#060b0f",
  },

  background: {
    main: "#ffffff",
    light: "#F5FEFD",
    dark: "#333333",
  },
};

// Mui Theme Settings

export const theme = createTheme({
  palette: {
    primary: {
      ...tokens.primary,
      main: tokens.primary[500],
    },
    secondary: {
      ...tokens.secondary,
      main: tokens.secondary[500],
    },
    blue: {
      ...tokens.blue,
      main: tokens.blue[500],
    },
    background: {
      default: tokens.background.main,
      light: tokens.background.light,
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "7rem",
      fontWeight: "800",
      letterSpacing: ".2rem",
    },
    h2: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "6rem",
      fontWeight: 700,
    },
    h3: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "4rem",
      fontWeight: 600,
    },
    h4: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "3rem",
      fontWeight: 500,
    },
    h5: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "3rem",
      fontWeight: 400,
    },
    h6: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "6px",
    },
    body1: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1rem",
    },
    body2: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: ".8rem",
    },
    body3: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1rem",
      textTransform: "uppercase",
    },
    subtitle1: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1rem",
      letterSpacing: "5px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: "1rem",
      textTransform: "uppercase",
      fontWeight: "600",
      letterSpacing: "4px",
      textAlign: "center",
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: ["Montserrat"].join(","),
          fontSize: "0.8rem",
          lineHeight: "1.4",
          fontWeight: "400",
          textTransform: "capitalize",
          letterSpacing: "2px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor : tokens?.background?.dark,
          // Your custom styles for the Container component
          // For example, you can change the background color
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "1.2rem",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          letterSpacing: "2px",
        },
      },
    },
  },
});

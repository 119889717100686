import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 0,
    padding: "8px", // Adjust padding according to your requirement
  },
  textBody: {
    fontSize: "10px",
  },
  boldText: {
    fontWeight: "bold",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: "4px",
  },
  flexItem1: {
    flex: 0.2,
    fontWeight: "bold",
  },
  flexItem2: {
    flex: 0.8,
  },
  paddingY: {
    paddingVertical: "12px",
  },
});

function TripDetailPDF({ invoiceData }) {
  return (
    <View style={styles.container}>
      <View>
        <Text style={[styles.textBody, styles.boldText, styles.paddingY]}>
          {`We hope you enjoyed your trip, ${invoiceData?.user?.name || ""}.`}
        </Text>
      </View>
      {invoiceData?.locations?.length > 0 && (
        <View style={styles.flexRow}>
          <Text style={[styles.textBody, styles.flexItem1]}>Pick-up</Text>
          <Text style={[styles.textBody, styles.flexItem2]}>
            : {invoiceData?.locations[0]?.address}
          </Text>
        </View>
      )}
      {invoiceData?.locations?.length > 1 && (
        <View style={styles.flexRow}>
          <Text style={[styles.textBody, styles.flexItem1]}>Drop</Text>
          <Text style={[styles.textBody, styles.flexItem2]}>
            :{" "}
            {invoiceData?.locations[invoiceData?.locations.length - 1]?.address}
          </Text>
        </View>
      )}
      <View style={styles.flexRow}>
        <Text style={[styles.textBody, styles.flexItem1]}>Trip Type</Text>
        <Text style={[styles.textBody, styles.flexItem2]}>
          :{" "}
          {`${invoiceData?.inCity ? "In-City" : "Outstation"} - ${
            invoiceData?.oneWay ? "One Way" : "Round"
          }`}
        </Text>
      </View>
      <View style={styles.flexRow}>
        <Text style={[styles.textBody, styles.flexItem1]}>Date</Text>
        <Text style={[styles.textBody, styles.flexItem2]}>
          :{" "}
          {moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "payment_completed"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}
        </Text>
      </View>
    </View>
  );
}

export default TripDetailPDF;

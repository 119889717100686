import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import Logo from "../../../Assets/dafy-logo.png";
import { DRIVER_TOS } from "../../../Constants/TermsOfService";

function DriverTermsAndCondition() {
  const alphabetArray = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "120px", height: "120px", ObjectFit: "contain" }}
        />
      </Box>
      <Typography
        color="secondary"
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "1.4rem",
            md: "2rem",
            xl: "3rem",
            textTransform: "uppercase",
          },
        }}
      >
        Terms Of Services
      </Typography>
      <Box
        className="container"
        sx={{
          width: "80%",
          color: "black",
          paddingBottom: "1rem",
        }}
      >
        {DRIVER_TOS.map((item, index) => {
          return (
            <>
              <Typography
                variant={"subtitle1"}
                gutterBottom
                sx={{
                  marginTop: index !== 0 ? "1rem" : 0,
                  fontSize: { xl: "1.2rem", md: "1rem" },
                  display: "flex",
                }}
              >
                {`${index + 1}. `}
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    fontSize: { xl: "1.2rem", md: "1rem" },
                  }}
                >{`${item?.title}`}</Typography>
              </Typography>
              <Box sx={{ marginLeft: "2rem" }}>
                {item?.items.map((desc, index) => {
                  if (desc.subheading) {
                    return (
                      <Typography variant="body1" key={index}>
                        <strong
                          style={{ textTransform: "lowercase" }}
                        >{`${alphabetArray[index]} . ${desc.subheading} : `}</strong>
                        {desc?.content}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography variant="body1" key={index}>
                        <strong
                          style={{ textTransform: "lowercase" }}
                        >{`${alphabetArray[index]} . `}</strong>
                        {desc}
                      </Typography>
                    );
                  }
                })}
              </Box>
            </>
          );
        })}
      </Box>
    </Paper>
  );
}

export default DriverTermsAndCondition;

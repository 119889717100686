import React, { useState } from "react";
import Logo from "../../Assets/dafy-logo.png";
import {
  Box,
  Button,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import LOGO from "../../Assets/dafy-logo.png";
import CustomTabPanel from "../../Components/CustomTabPanel";
import DafySupport from "./components/DafySupport";
import DafyPartnerSupport from "./components/DafyPartnerSupport";
import { theme } from "../../theme";

function Support() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOnSendEmail = () => {
    const email = "contact@dafy.in";
    const subject = encodeURIComponent("Suggestion for Additional FAQs");
    const emailBody = encodeURIComponent(
      "Hi there,\n\nI would like to suggest the following topics to be added to your FAQ section:\n\n[Please type your suggestions here]\n\nThank you!"
    );

    window.open(`mailto:${email}?subject=${subject}&body=${emailBody}`);
  };
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        boxShadow: "none",
        height: "100%",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "120px", height: "120px", ObjectFit: "contain" }}
        />
      </Box>
      <Typography variant="h6">Help & Support</Typography>
      <Divider variant="middle" sx={{ width: "80%", marginY: "0.5rem" }} />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <Tab
          icon={
            <img
              src={LOGO}
              alt="logo"
              style={{ width: "60px", height: "40px", objectFit: "contain" }}
            />
          }
          label="DAFY"
        />
        <Tab
          icon={
            <img
              src={LOGO}
              alt="logo"
              style={{ width: "60px", height: "40px", objectFit: "contain" }}
            />
          }
          label="DAFY Partner"
        />
      </Tabs>
      <CustomTabPanel index={0} value={value}>
        <DafySupport />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={value}>
        <DafyPartnerSupport />
      </CustomTabPanel>
      <Box
        sx={{
          backgroundColor: theme.palette.primary[400],
          paddingX: "1rem",
          paddingY: "0.8rem",
          display: "flex",
          alignItems: "center",
          borderRadius: "0.5rem",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "1rem" }}>Have more questions ?</Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mx: "1rem" }}
          onClick={handleOnSendEmail}
        >
          Send Email
        </Button>
      </Box>
    </Paper>
  );
}

export default Support;

export const FAQ_CONTENT_DAFY = [
  {
    question: "How Does DAFY Work?",
    key: "dafyCoins",
    desc: "Experience the convenience of India's pioneering on-demand driver network with DAFY. Easily book a professional, police-verified driver through our app anytime, anywhere. Enjoy a world-class, worry-free journey as all DAFY drivers are meticulously background checked and professionally trained. Embrace the comfort of your backseat – try DAFY today!",
  },
  {
    question: "In Which cities can I book a DAFY driver?",
    key: "redeemCoins",
    desc: `DAFY provides services in the following cities in Kerala:
    `,
    subDesc: [
      "Cochin",
      "Kottayam",
      "Help us bring our services to your city next by expressing your interest!",
    ],
  },
  {
    question: "What is the difference between Round Trip and One Way?",
    key: "wallet",
    desc: "A Round Trip concludes at its starting point, while a One Way trip finishes at a different location than where it began.",
  },
  {
    question: "How soon can I get a driver?",
    key: "get Driver",
    desc: "Get a driver promptly with DAFY! Use our mobile app to book a nearby driver instantly, or schedule one for up to one hour later. For outstation trips, plan ahead and book a driver three hours before your desired start time. DAFY ensures timely and convenient service wherever you are",
  },
  {
    question: "How do I contact my driver?",
    key: "contact",
    desc: "Contacting your DAFY driver is easy! Simply check the app for your driver's contact details once assigned. You can also track their journey to your pickup point through the app. If your booking wasn't made via our app, the driver's contact details will be sent to your registered mobile number via SMS.",
  },
  {
    question: "How do I rate my experience?",
    key: "rate experience",
    desc: "Rate your DAFY experience effortlessly! After completing your booking, use the app to provide a rating and leave comments. Your input helps us enhance your future experiences.",
  },
  {
    question: "Can we trust your drivers?",
    key: "trust",
    desc: "Absolutely, trust in DAFY! Prioritizing customer safety, we conduct rigorous background checks, police verifications and provide comprehensive training for all our drivers. Your well-being is our top priority, ensuring you and your loved ones are in secure hands during every DAFY journey.",
  },
  {
    question: "How do we make sure to provide you with high quality drivers?",
    key: "quality drivers",
    desc: "We maintain high standards by investing in continuous improvement for our drivers. While they come with years of diverse driving experience, we enhance their skills through training in soft skills, route knowledge, and technology. Your feedback is crucial – at the end of each trip, please take a moment to rate your driver. This process enables us to identify areas for improvement and applaud those delivering an excellent service.",
  },
  {
    question: "How to hire?",
    key: "hire drivers",
    desc: "Hiring a driver is simple! Use the DAFY app on Android/iOS, or visit the website. Choose your location, provide journey details (pickup date/time, car type, etc.), and place your request. We'll notify you promptly if a driver is available. Receive the driver's details 30 minutes before your booking time for a seamless experience.",
  },
  {
    question: "How do I track my driver",
    key: "track drivers",
    desc: "Track your driver effortlessly by opening the DAFY app and navigating to your current booking. If you encounter any issues, don't hesitate to reach out to us through the support option for assistance.",
  },
  {
    question: "How much in advance do I have to place my booking?",
    key: "place Bookings",
    desc: "Secure a DAFY driver promptly by booking immediately if one is nearby. For advance bookings, provide a one-hour heads-up, and we'll ensure a timely arrangement for you. DAFY is ready to serve whenever you need!",
  },
  {
    question: "How do I cancel a booking?",
    key: "Cancel Booking",
    desc: "To cancel a booking, open the DAFY app and go to your booking details screen. Click the 'Cancel' icon. The cancel button will be disabled if the driver is nearby, but you can contact the support team to notify them of the change of travel plans. It's appreciated if you can notify us at least one hour in advance, allowing the driver to assist someone else. Thank you for your understanding.",
  },
  {
    question: "I have been charged a cancellation fee",
    key: "Cancel Charges",
    desc: "Cancellation fees are applicable if you cancel a driver service booking after the grace period of 5 minutes once the driver is en route to the pickup location or reached nearby. This fee compensates the driver for their time and effort in reaching your location. Feel free to reach out if you have any concerns or need further assistance.",
  },
  {
    question: "Why is there a One side charge/conveyance charge?",
    key: "One side Charges",
    desc: "The One Side charge is implemented to cover the cost of the driver's return to the trip's origin location. This ensures the driver can efficiently retrieve their vehicle, facilitating quicker availability for the next customer. It's a measure to optimize resources and enhance overall service responsiveness.",
  },

  {
    question: "Do you provide Invoices?",
    key: "invoices",
    desc: "we'll send a link to register mobile number to download if user request for invoice.we are not verifying emails.",
  },
];

export const FAQ_CONTENT_DAFY_PARTNER = [
  {
    question: "How do I receive ride requests?",
    key: "ride request",
    desc: "Once logged into the app and set as available, you'll receive ride requests automatically. These requests will appear on your screen with details such as pick-up location, drop-off location, and estimated fare.",
  },
  {
    question: "Can I choose which rides to accept?",
    key: "redeemCoins",
    desc: "No, Dafy Drivers does not offer the option to decline any ride; every ride will be automatically assigned to the nearest available driver. In case of an emergency, the driver will need to call the drive manager to request reassignment of any rides.",
  },
  {
    question: "How do I navigate to the pick-up and drop-off locations?",
    key: "wallet",
    desc: "The Driver App is integrated with GPS navigation systems like Google Maps. Simply tap on the navigation button within the app to get directions to the pick-up and drop-off points.",
  },
  {
    question: "How do I track my earnings?",
    key: "earnings",
    desc: "The app provides a detailed breakdown of your earnings. You can view your earnings in real-time and access past earnings statements from the Earning button at the bottom of the app",
  },
  {
    question: "How and when do I get paid?",
    key: "salary",
    desc: "For cash payments, the company's commission will be deducted from the driver's prepaid balance. For online payments from customers, the amount will be added to the driver's balance and credited to their designated bank account on a weekly basis.",
  },
  {
    question: "What should I do in case of an emergency during a ride?",
    key: "rate experience",
    desc: "If you face an emergency situation during a ride, utilise either the in-app emergency button or directly contact the driver manager.",
  },
  {
    question: "How can I improve my driver rating?",
    key: "rating",
    desc: "Providing excellent customer service, adhering to traffic laws, and minimising cancellations are key factors in improving your driver rating. Additionally, prompt and safe driving and positive interactions with passengers contribute to a higher rating.",
  },
];

import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import Logo from "../../../Assets/dafy-logo.png";

function SecureBookingTermsAndCondition() {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "120px", height: "120px", ObjectFit: "contain" }}
        />
      </Box>
      <Typography
        color="secondary"
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "1.4rem",
            md: "2rem",
            xl: "3rem",
            textTransform: "uppercase",
          },
        }}
        gutterBottom
      >
        Secure Pay Terms and Conditions
      </Typography>
      <Box
        className="container"
        sx={{
          width: "80%",
          color: "black",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="body1" gutterBottom>
          1. Introduction
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          These terms and conditions (&quot;Terms&quot;) govern the collection
          of an amount as Secure Pay for the security of your vehicle for a
          particular drive when using a driver booked through Dafy Online
          Private Limited&#39;s app. By opting for Secure Pay, you agree to
          comply with these Terms.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          2. Definition of Secure Pay
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          Secure Pay is a specified amount collected from the customer to
          compensate damages caused to their vehicle during a particular drive.
          The maximum requestable amount under this compensation is ₹10,000.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          3. Eligibility
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          To be eligible for Secure Pay, you must :{" "}
        </p>
        <ul style={{ marginLeft: "1rem" }}>
          <li>1. Have a proper and valid insurance policy for the vehicle.</li>
          <li>2. Ensure the vehicle is legally fit and registered.</li>
          <li>
            3. Agree to these Terms and the conditions outlined in the secure
            pay policy.
          </li>
        </ul>
        <br />
        <Typography variant="body1" gutterBottom>
          4. Secure Pay Amount
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          The Secure Pay amount will be determined based on various factors,
          including but not limited to the vehicle type, drive duration, and
          compensation required. The amount will be communicated to you during
          the booking of the drive.
        </p>{" "}
        <br />
        <Typography variant="body1" gutterBottom>
          5. Payment Terms
        </Typography>
        <ul style={{ marginLeft: "1rem" }}>
          <li>
            1. Secure Pay must be opted by customer before the start of the
            drive.
          </li>
          <li>
            2. Payment can be made through the available payment methods
            specified at the time of bill generation.
          </li>
          <li>
            3. Failure to make the payment will result in the cancellation of
            the Secure Pay compensation for the drive.
          </li>
        </ul>
        <br />
        <Typography variant="body1" gutterBottom>
          6. Compensation Details
        </Typography>
        <ul style={{ marginLeft: "1rem" }}>
          <li>
            1. The maximum requestable amount under Secure Pay is ₹10,000.
          </li>
          <li>
            2. compensation includes specific risks during the drive period as
            outlined in the secure pay policy.
          </li>
          <li>
            3. Depreciation on parts will be considered before sanctioning any
            request.
          </li>
          <li>
            4. This compensation will only indemnify the damage caused to the
            vehicle by DAFY driver.
          </li>
          <li>
            5. Natural causes like fire, flood etc. will not be indemnified.
          </li>
          <li>
            6. The compensation is valid only for the duration and route of the
            specified drive for which the secure pay is opted.
          </li>
        </ul>
        <br />
        <Typography variant="body1" gutterBottom>
          7. Pre-Drive Vehicle Inspection
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          The customer agrees to have images of the vehicle taken by the driver
          before the start of the drive. This is a mandatory requirement for the
          Secure Pay compensation to be valid.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          8. Requests Process
        </Typography>
        <ul style={{ marginLeft: "1rem" }}>
          <li>
            1. You must raise a ticket within 24 hours of trip completion if any
            issue arises.
          </li>
          <li>2. You can raise a ticket by calling “+91 7592 933 933”.</li>
          <li>
            3. Provide all necessary documentation and information required to
            process the request.
          </li>
          <li>
            4. An evaluator will be appointed to valuate the issue. Request
            amount will be finalized based on the report submitted by the
            evaluator.
          </li>
          <li>
            5. The request provider will assess the request as per the policy
            terms and conditions.
          </li>
          <li>
            6. If the vehicle owner drives the car during the incident, the
            request will be rejected.
          </li>
        </ul>
        <br />
        <Typography variant="body1" gutterBottom>
          9. Changes to Terms
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          We reserve the right to modify these Terms at any time. Any changes
          will be communicated to you before they take effect.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          10. Limitation of Liability
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          Our liability is limited to the compensation amount specified in the
          secure pay policy, subject to a maximum requestable amount of ₹10,000.
          We are not responsible for any indirect, incidental, or consequential
          damages arising from the use of Secure Pay.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          11. Governing Law
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          These Terms are governed by and construed in accordance with the laws
          of the jurisdiction of Cochin, Kerala.
        </p>
        <br />
        <Typography variant="body1" gutterBottom>
          12. Contact Information
        </Typography>
        <p style={{ marginLeft: "1rem" }}>
          For any questions or concerns regarding Secure Pay or these Terms,
          please contact our customer service team at contact@dafy.in.
        </p>
        <br />
        <p style={{ marginLeft: "1rem" }}>
          By opting for Secure Pay, you acknowledge that you have read,
          understood, and agreed to these Terms.
        </p>
        <br />
      </Box>
    </Paper>
  );
}

export default SecureBookingTermsAndCondition;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function StatusPopup({ showModal, onClose, setShowModal, message }) {
  return (
    <Dialog
      open={showModal}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        variant="subtitle1"
        sx={{ textAlign: "center" }}
      >
        Account Status
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant="body1">
          {message || "Your account deletion request is on Process"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StatusPopup;

export const styles = {
  companyInfo: {
    fontSize: "12px",
    fontWeight: "700",
  },
  flexOne: {
    flex: 1,
  },
  logo: {
    width: 60,
    height: 60,
    objectFit: "cover",
  },
  titleText: {
    textTransform: "uppercase",

    fontSize: { xs: "1.1rem", xl: "1.5rem", lg: "1.6rem" },
  },
  addressText: {
    fontSize: "10px",
    fontWeight: 400,
  },
  textHeader: {
    fontSize: { xs: "0.8rem", lg: "1rem" },
    fontWeight: "bold",
    flex: 1,
  },
  textBody: {
    fontSize: "12px",
    marginVertical: "2px",
  },

  bodyCell: {
    margin: 0,
    lineHeight: { xs: "6px" },
  },
  bodyCell2: {
    margin: 0,
    lineHeight: 0,
  },
  gstCell: (border, bold, btmBorder) => {
    return {
      border: "0px solid #fff",
      borderRight: border && "1px solid #000",
      lineHeight: 0,
      margin: 0,

      fontWeight: bold ? "bold" : "none",
      borderBottom: btmBorder && "1px solid #000",
    };
  },
  gstCell2: {
    border: "0px solid #fff",
    borderRight: "1px solid #000",
    lineHeight: 0,
    margin: 0,
  },
};

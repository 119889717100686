import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import NatoFont from "../../../Assets/fonts/NotoSans-Regular.ttf";

Font.register({
  family: "customFont",
  src: NatoFont,
});
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderTopWidth: "1px",
    paddingTop: 8,
    paddingBottom: 8,
  },
  textContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 4,
    fontFamily: "customFont",
  },
});
function FareSummaryPDF({ invoiceData }) {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          {`Your total fare ₹ ${(invoiceData.amount / 100).toFixed(
            2
          )} includes a GST of ₹ ${(
            (invoiceData.companyGst +
              invoiceData.driverCommissionGst +
              invoiceData.secureAmountGst) /
            100
          ).toFixed(2)}`}
        </Text>
      </View>
    </View>
  );
}

export default FareSummaryPDF;

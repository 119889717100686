import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../../theme";
import { grey } from "@mui/material/colors";
import axios from "axios";
import StatusPopup from "./components/StatusPopup";
function DeleteCustomer() {
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      setError("Phone Number is Required");
    } else {
      setError("");
    }
  };
  const handleSubmit = async () => {
    const checkPhone = userPhoneNumber.length === 10;
    if (!checkPhone) {
      setError("Phone Number must be 10 digits");
      return;
    }

    try {
      const response = await axios.post(
        "http://api.dafy.in/api/v1/admin/deleteAccount/customer",
        {
          phone: `91${userPhoneNumber}`,
        }
      );
      if (response.data?.status === "success") {
        if (response.data?.data?.driver) {
          setMessage(
            "Your account deletion request is being processed and will be completed within 7 days."
          );
        } else {
          setMessage("Your account has been successfully deleted.");
        }
      } else {
        setMessage("Error checking account status. Please try again later.");
      }
      setShowModal(true);
    } catch (error) {
      setShowModal(true);
      setMessage("Error checking account status. Please try again later.");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: { xs: "100%", md: "60%", lg: "50%" },
          border: `1px solid ${theme.palette.primary.main}`,
          padding: "1rem",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", fontSize: { xs: "1.2rem", lg: "auto" } }}
        >
          Account Deletion
        </Typography>
        <CardContent>
          <Typography
            variant="body1"
            color={grey[800]}
            sx={{ marginBottom: "1rem", marginX: "0.5rem" }}
          >
            Enter your phone number below to check the status of your account
            deletion request.
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={userPhoneNumber}
            onChange={(e) => {
              setUserPhoneNumber(e.target.value);
              validatePhoneNumber(e.target.value);
            }}
            onBlur={() => validatePhoneNumber(userPhoneNumber)}
            label="Phone Number"
            helperText={error}
            error={Boolean(error)}
            fullWidth
          />
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleSubmit}>
            Check Status
          </Button>
        </CardActions>
      </Card>
      <StatusPopup
        message={message}
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => setShowModal(false)}
      />
    </Box>
  );
}

export default DeleteCustomer;

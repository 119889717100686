import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  companyInfo: { fontSize: "20px", fontWeight: "bold", marginBottom: 10 },
  addressText: {
    fontSize: "10px",
  },

  flexOne: { flex: 1 },
  billContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  logo: {
    width: 60,
    height: 60,
    objectFit: "cover",
  },
  textHeader: {
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: 10,
  },
  textBody: {
    fontSize: "10px",
    marginVertical: "2px",
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 10,
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  tableCol: (width) => ({
    width: width,
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    padding: 5,
  }),
  tableCell: (align) => ({
    textAlign: align || "left",
    fontSize: 10,
    padding: 2,
  }),
  summary: {
    marginTop: 20,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  summaryLabel: {
    fontWeight: "bold",
  },
  summaryValue: {
    textAlign: "right",
  },
});

export const SERVICES_GRID = [
  {
    id: 1,
    title: "One Way Trip",
    background: require("../../Assets/Dafy-Single-Trip.gif"),
    description: [
      "Hire professional drivers to pick up from bars and eliminate the hustle of driving",
      "No more driving in heavy traffic and locating a parking spot",
      "Hire drivers for airport pickup or drop-off services",
    ],
    icon: require("../../Assets/singleTrip.png"),
    xs: "12",
    sm: "12",
    md: "4",
    lg: "4",
    xl: "4",
  },
  {
    id: 2,
    title: "Round Trip",
    background: require("../../Assets/Dafy-Round-Trip.gif"),
    description: [
      "Hire professional drivers to commute your vehicle.",
      "Simply get around in town for midnight cravings, shopping, and night parties.",
      "Make a quick stop at any stores/pharmacies on your rides.",
      // " No more delays on your meeting schedules"
    ],
    icon: require("../../Assets/Icons/roundTrip.png"),
    xs: "12",
    sm: "12",
    md: "4",
    lg: "4",
    xl: "4",
  },
  {
    id: 3,
    title: "Hospital Assitance",
    background: require("../../Assets/icons8-play.gif"),
    description: [
      "You can trust that your loved ones are in capable and caring hands",
      "We prioritize the physical and emotional well-being of seniors",
      "Our experienced drivers offer a comfortable and supportive hospitalization environment.",
    ],
    icon: require("../../Assets/hospital.png"),
    xs: "12",
    sm: "12",
    md: "4",
    lg: "4",
    xl: "4",
  },
];

export const PROFESSIONAL_DRIVERS = [
  "Hire the professionals,Experience a smooth and stress-free journey.",
  "We'll take care of everything, from pick-up to drop-off, ensuring you arrive at your destination on time and in style.",
  "Forget about the hassle of navigating unfamiliar roads or finding parking spaces.",
  "Our experienced drivers are here to provide a comfortable and supportive environment during your trip.",
];

export const HOSPITAL_ASSITANCE = [
  "Our driver assistants also act as a personal assistant who provides support for personal care.",
  "Taking the assessments, we give reports to the person.",
  "Our user-friendly platform helps with booking as well as taking them for tests and assisting with administrative tasks like Buying medicines and paying bills",
  "They handle functions within the hospital which carry out much of the groundwork.",
];

import {
  useTheme,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import "./ServiceFeaturing.css";

import MainLayOut from "../../Components/MainLayOut";

// Importing Local Images
import ServiceBanner1 from "../../Assets/ServiceBanner.png";
import ServiceBanner2 from "../../Assets/ServiceBanner2.png";
import {
  HOSPITAL_ASSITANCE,
  PROFESSIONAL_DRIVERS,
} from "../../Constants/Services";

import GppGoodIcon from "@mui/icons-material/GppGood";
export default function ServiceFeaturing() {
  const theme = useTheme();
  return (
    <>
      <MainLayOut
        title={""}
        subtitle={"Start Your Stress-Free Journey"}
        bgrColor={"background"}
        compHeight="auto"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "5rem",
            alignItems: "center",
            position: "relative",
            paddingTop: { xs: "1rem", md: "1rem", xl: "1rem" },
            paddingBottom: { xs: "3rem", md: "3rem", xl: "3 rem" },
          }}
        >
          <Box
            sx={{
              width: { xs: "80%", sm: "80%", md: "75%", lg: "70%", xl: "60%" },
              backgroundColor: theme.palette.blue[300],
              position: "relative",
              display: {
                xs: "block",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderBottomLeftRadius: {
                xs: "2rem",
                sm: "8rem",
                md: "10rem",
                lg: "10rem",
                xl: "10rem",
              },
              borderTopLeftRadius: {
                xs: "2rem",
                sm: "8rem",
                md: "10rem",
                lg: "10rem",
                xl: "10rem",
              },
              borderBottomRightRadius: "2rem",
              borderTopRightRadius: "2rem",
              paddingLeft: {
                xs: "0",
                sm: "8rem",
                md: "15rem",
                lg: "20rem",
                xl: "22rem",
              },
              paddingTop: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1rem",
                lg: "2",
                xl: "1",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "", sm: "45%", md: "45%", lg: "50%", xl: "50%" },

                height: {
                  xs: "",
                  sm: "130%",
                  md: "130%",
                  lg: "140%",
                  xl: "auto",
                },
                position: "absolute",
                left: "-5rem",
              }}
            >
              <img
                src={ServiceBanner1}
                alt="Service Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Typography
              variant="h6"
              color="primary"
              textAlign={"center"}
              key={"2"}
              sx={{
                fontSize: {
                  xs: "1.1rem",
                  sm: "1.2rem",
                  md: "1.3rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
                letterSpacing: { sm: "3px", lg: "6px", xl: "6px" },
              }}
            >
              Professional Drivers
            </Typography>
            <List>
              {PROFESSIONAL_DRIVERS.map((item) => (
                <ListItem
                  sx={{
                    py: { xs: "0", sm: "0", md: "0", lg: "4px", xl: "5px" },
                  }}
                >
                  <ListItemIcon>
                    <GppGoodIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    paddingTop={0}
                    primaryTypographyProps={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "0.9rem",
                        xl: "1rem",
                      },
                    }}
                    sx={{ color: theme.palette.grey[100] }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box
            sx={{
              width: { xs: "80%", sm: "80%", md: "75%", lg: "70%", xl: "60%" },
              backgroundColor: theme.palette.blue[300],
              position: "relative",
              display: {
                xs: "block",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderBottomLeftRadius: "2rem",
              borderTopLeftRadius: "2rem",
              borderBottomRightRadius: {
                xs: "2rem",
                sm: "8rem",
                md: "10rem",
                lg: "10rem",
                xl: "10rem",
              },
              borderTopRightRadius: {
                xs: "2rem",
                sm: "8rem",
                md: "10rem",
                lg: "10rem",
                xl: "10rem",
              },
              paddingRight: {
                xs: "0rem",
                sm: "8rem",
                md: "15rem",
                lg: "20rem",
                xl: "22rem",
              },

              paddingTop: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1rem",
                lg: "2",
                xl: "1",
              },
            }}
          >
            <Box
              sx={{
                width: { xs: "", sm: "45%", md: "45%", lg: "50%", xl: "50%" },

                height: {
                  xs: "",
                  sm: "130%",
                  md: "130%",
                  lg: "140%",
                  xl: "auto",
                },
                position: "absolute",
                right: "-5rem",
              }}
            >
              <img
                src={ServiceBanner2}
                alt="Service Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Typography
              variant="h6"
              color="primary"
              textAlign={"center"}
              key={"2"}
              sx={{
                fontSize: {
                  xs: "1.1rem",
                  sm: "1.2rem",
                  md: "1.3rem",
                  lg: "1.5rem",
                  xl: "2rem",
                },
                letterSpacing: { sm: "3px", lg: "6px", xl: "6px" },
              }}
            >
              HOSPITAL ASSITANCE
            </Typography>
            <List>
              {HOSPITAL_ASSITANCE.map((item) => (
                <ListItem
                  sx={{
                    py: { xs: "0", sm: "0", md: "0", lg: "4px", xl: "8px" },
                  }}
                >
                  <ListItemIcon>
                    <GppGoodIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={item}
                    paddingTop={0}
                    primaryTypographyProps={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "0.9rem",
                        xl: "1rem",
                      },
                    }}
                    sx={{ color: theme.palette.grey[100] }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "", sm: "", md: "", lg: "31%", xl: "31%" },
                height: "auto",
                position: "absolute",
                right: "10%",
              }}
            >
              <img
                src={ServiceBanner2}
                alt="Service Banner"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Box
              sx={{
                width: {
                  xs: "60%",
                  sm: "60%",
                  md: "60%",
                  lg: "60%",
                  xl: "80%",
                },
                backgroundColor: theme.palette.blue[300],
                borderTopLeftRadius: "2rem",
                borderBottomLeftRadius: "2rem",
                borderBottomRightRadius: "10rem",
                borderTopRightRadius: "10rem",
                paddingRight: "30%",
                py: "0.8rem",
              }}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Box
                display={"flex"}
                sx={{ width: "65%", height: "100%" }}
                flexDirection={"column"}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  textAlign={"center"}
                  key={"2"}
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1.1rem",
                      md: "1.3rem",
                      lg: "1.5rem",
                      xl: "2rem",
                    },
                    letterSpacing: { sm: "3px", lg: "6px", xl: "6px" },
                  }}
                >
                  Hospital Assistance
                </Typography>
                <List>
                  {PROFESSIONAL_DRIVERS.map((item) => (
                    <ListItem>
                      <ListItemIcon>
                        <GppGoodIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={item}
                        sx={{ color: theme.palette.grey[100] }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </MainLayOut>
    </>
  );
}

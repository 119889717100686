import { Box, Typography } from "@mui/material";
import React from "react";

export default function FooterItem({ item }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start", py: "2px" }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: "0.9rem",
          textAlign: {
            xs: "center",
            sm: "center",
            md: "left",
            lg: "left",
            xl: "left",
          },
        }}
      >
        {item?.link}
      </Typography>
    </Box>
  );
}

import React from "react";
import { Page, View, Document, StyleSheet } from "@react-pdf/renderer";
import DafyDetailsPDF from "../PDFComponents/DafyDetailsPDF";
import TripDetailPDF from "../PDFComponents/TripDetailPDF";
import FareDetailPDF from "../PDFComponents/FareDetailPDF";
import DriverDetailPDF from "../PDFComponents/DriverDetailPDF";
import FareSummaryPDF from "../PDFComponents/FareSummaryPDF";
import InfoTextPDF from "../PDFComponents/InfoTextPDF";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
});
function InvoicePDF({ invoiceData, title = "TAX INVOICE" }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ border: "1px solid black", flex: 1 }}>
          <DafyDetailsPDF title={""} />
          <TripDetailPDF invoiceData={invoiceData} />
          <FareSummaryPDF invoiceData={invoiceData} />
          <FareDetailPDF invoiceData={invoiceData} />
          <DriverDetailPDF invoiceData={invoiceData} />
          <InfoTextPDF invoiceData={invoiceData} />
        </View>
      </Page>
    </Document>
  );
}

export default InvoicePDF;

import { ToWords } from "to-words";

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

export const convertToWords = (finalAmount) => {
  let amount = finalAmount || 0;
  let amountInWords = toWords.convert(parseFloat(amount), {
    currency: true,
  });
  return amountInWords;
};

export const getInvoiceNumber = (id) => {
  let str = id?.substring(id?.length - 5);
  return str;
};

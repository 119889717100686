import { Box, Typography } from "@mui/material";
import React from "react";

function FareSummary({ invoiceData }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ border: "1px solid black", borderBottom: 0 }}
    >
      <Box
        flex={1}
        display="flex"
        justifyContent="space-between"
        sx={{ paddingY: 1 }}
      >
        <Typography
          sx={{
            fontSize: { xs: "12px" },
            fontWeight: 700,
            marginLeft: 1,
            fontFamily: "sans-serif",
          }}
        >
          {`Your total fare ₹${(invoiceData.amount / 100).toFixed(
            2
          )} includes a GST of ₹${(
            (invoiceData.companyGst +
              invoiceData.driverCommissionGst +
              invoiceData.secureAmountGst) /
            100
          ).toFixed(2)}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default FareSummary;

import React from "react";
import MainLayOut from "../../Components/MainLayOut";
import { Box, Grid, Link, Typography } from "@mui/material";

// Importing Icons

import { theme } from "../../theme";
const contactDetails = [
  {
    id: 1,
    logo: require("../../Assets/Icons/map.png"),
    subtitle:
      "1st Floor,Kalyani Tower,Soniya Nagar,PJ Antony Road,Palarivattom,Ernakulam,Kerala",
    title: "Our Location",
    xs: "12",
    sm: "12",
    md: "6",
    lg: "4",
    xl: "4",
    link: "https://www.google.com/maps/dir/10.0059959,76.3064487/'10.006010,76.306469'/@10.0060553,76.3038829,17z/data=!4m7!4m6!1m1!4e1!1m3!2m2!1d76.306469!2d10.00601?entry=ttu",
  },
  {
    id: 2,
    logo: require("../../Assets/Icons/phone-call.png"),
    subtitle: "+91 7592933933",
    title: "Phone",
    xs: "12",
    sm: "12",
    md: "6",
    lg: "4",
    xl: "4",
    link: "tel:+91 7592933933",
  },
  {
    id: 3,
    logo: require("../../Assets/Icons/mail.png"),
    subtitle: "contact@dafy.in",
    title: "E-mail us!",
    xs: "12",
    sm: "12",
    md: "12",
    lg: "4",
    xl: "4",
    link: "mailto:contact@dafy.in",
  },
];
function Contact() {
  return (
    <MainLayOut
      title={"Contact us"}
      subtitle={"Contact Now For Booking!"}
      compHeight="auto"
      bgrColor={theme.palette.blue[500]}
      subTitleColor={theme.palette.grey[200]}
      id={"Contact"}
    >
      <Typography
        variant="subtitle1"
        
        color={theme.palette.grey[200]}
        textAlign={"center"}
        paddingTop={"2rem"}
        px={"2rem"}
        mt={"-1.4rem"}
        sx={{
          fontSize: {
            xs: "0.6rem",
            sm: ".6rem",
            md: ".8rem",
            lg: "0.9rem",
            xl: "1rem",
          },
          letterSpacing: {
            xs: "2px",
            sm: "3px",
            md: "4px",
            lg: "5px",
            xl: "5px",
          },
        }}
      >
        Simplify booking, get reliable transportation support - for your daily commute or special occasions
      </Typography>
      <Grid
        container
        rowGap={{ xs: "5rem", sm: "5rem", xl: "8rem" }}
        sx={{ width: "100%", height: "100%" }}
        display={"flex"}
        py={"5rem"}
        px={'2rem'}
        alignItems={"center"}
        justifyContent={"space-around"}
      >
        {contactDetails.map((element, index) => {
          return (
            <Grid
              item
              xs={element.xs}
              sm={element.sm}
              md={element.md}
              lg={element.lg}
              xl={element.xl}
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link href={element.link} target="_blank" underline="false">
                <Box
                  key={element.id}
                  className="roadmap-element"
                  sx={{
                    width: {
                      xs: "360px",
                      sm: "380px",
                      md: "380px",
                      lg: "380px",
                      xl: "380px",
                    },
                    height: {
                      xs: "180px",
                      sm: "180px",
                      md: "200px",
                      lg: "200px",
                      xl: "200px",
                    },
                    border: "1px solid #fcd12a",
                    borderRadius: "10px",
                    paddingTop: {
                      xs: "3rem",
                      sm: "3rem",
                      md: "3rem",
                      lg: "5rem",
                      xl: "5rem",
                    },
                    '&:hover' : {
                      border :"2px solid #fff"
                    }
                  }}
                  display={"flex"}
                  justifyContent={"space-around"}
                  flexDirection={"column"}
                  position={"relative"}
                  alignItems={"center"}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "70px",
                        lg: "95px",
                        xl: "95px",
                      },
                      height: {
                        xs: "70px",
                        lg: "95px",
                        xl: "95px",
                      },
                      borderRadius: "10px",
                      border: "1px solid #fcd12a",
                      objectFit: "contain",
                      top: {
                        xs: "-18%",
                        sm: "-19%",
                        md: "-20%",
                        lg: "-25%",
                        xl: "-25%",
                      },
                      transform: "rotate(45deg)",
                      '&:hover' : {
                        border :"2px solid #fff",
                        transition:"all 0.3s ease-in-out" 
                      }
                    }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    position={"absolute"}
                    backgroundColor={theme.palette.blue[500]}
                  >
                    <img
                      src={element.logo}
                      alt={`Logo ${element.id}`}
                      style={{
                        height: "50%",
                        width: "50%",
                        transform: "rotate(-45deg)",
                        padding: "0.3rem",
                      }}
                    />
                  </Box>
                  <Typography
                    variant={"body1"}
                    color={theme.palette.grey[400]}
                    textAlign={"center"}
                  >
                    {element.subtitle}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    // position={"absolute"}
                    // bottom={"10%"}
                    textTransform={"uppercase"}
                  >
                    {element.title}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </MainLayOut>
  );
}

export default Contact;

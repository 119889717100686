import { Box, Typography } from "@mui/material";
import React from "react";

function InfoText() {
  return (
    <Box
      sx={{
        border: "1px solid #000",
        paddingX: "0.5rem",
        paddingY: 1,
      }}
    >
      <Typography sx={{ fontSize: { xs: "12px", fontFamily: "sans-serif" } }}>
        Fares are inclusive of GST. You can download the tax invoice from the
        trip details page for a tax breakdown
      </Typography>
    </Box>
  );
}

export default InfoText;

import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import moment from "moment";

function TripDetails({ invoiceData }) {
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
        padding: { xs: 1, md: 2 },
      }}
    >
      <Box display="flex">
        <Typography
          sx={{ ...styles.textBody, fontWeight: 700, py: "1rem" }}
        >{`We hope you enjoyed your trip, ${
          invoiceData?.user?.name || ""
        }.`}</Typography>
      </Box>
      {invoiceData?.locations?.length > 0 ? (
        <Box display="flex">
          <Typography sx={{ ...styles.textBody, fontWeight: 700, flex: 0.2 }}>
            Pick-up
          </Typography>
          <Typography sx={{ ...styles.textBody, flex: 0.8 }}>
            : {invoiceData?.locations[0]?.address}
          </Typography>
        </Box>
      ) : null}
      {invoiceData?.locations?.length > 1 ? (
        <Box display="flex">
          <Typography sx={{ ...styles.textBody, fontWeight: 700, flex: 0.2 }}>
            Drop
          </Typography>
          <Typography sx={{ ...styles.textBody, flex: 0.8 }}>
            :{" "}
            {invoiceData?.locations[invoiceData?.locations.length - 1]?.address}
          </Typography>
        </Box>
      ) : null}
      <Box display="flex">
        <Typography sx={{ ...styles.textBody, fontWeight: 700, flex: 0.2 }}>
          Trip Type
        </Typography>
        <Typography sx={{ ...styles.textBody, flex: 0.8 }}>
          :{" "}
          {`${invoiceData?.inCity ? "In-City" : "Outstation"} - ${
            invoiceData?.oneWay ? "One Way" : "Round"
          }`}
        </Typography>
      </Box>

      <Box display="flex">
        <Typography sx={{ ...styles.textBody, fontWeight: 700, flex: 0.2 }}>
          Date
        </Typography>
        <Typography sx={{ ...styles.textBody, flex: 0.8 }}>
          :{" "}
          {moment(
            invoiceData?.statusUpdateTimes?.find(
              (item) => item.status === "payment_completed"
            )?.time,
            "x"
          ).format("DD MMM YYYY hh:mm A")}
        </Typography>
      </Box>
    </Box>
  );
}

export default TripDetails;

import React from "react";

import { Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import twitter from "../../Assets/Icons/twitter.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const QuickLinks = [
  {
    id: "1",

    link: "Home",
  },
  {
    id: "2",
    link: "Service",
  },
  {
    id: "3",
    link: "About-Us",
  },
  {
    id: "4",
    link: "Team",
  },
  {
    id: "5",
    link: "Testimonials",
  },
];

export const ContactItems = [
  {
    id: 1,
    link: "1st Floor,Kalyani Tower,Soniya Nagar,PJ Antony Road,Palarivattom,Ernakulam,Kerala",
  },
  {
    id: 2,
    link: "+91 7592933933",
  },
  // {
  //   id: 3,
  //   link: (
  //     <Link href="https://dafy.in/" underline="none" color={"secondary"}>
  //       <Typography variant="body1" fontSize={"0.9rem"}>
  //         www.dafy.in
  //       </Typography>
  //     </Link>
  //   ),
  // },
];

export const SocialMedia = [
  {
    id: "1",
    logo: <FacebookIcon sx={{ fontSize: "30px" }} />,
    link: "https://www.facebook.com/dafyonline",
  },
  {
    id: "2",
    logo: <InstagramIcon sx={{ fontSize: "30px" }} />,
    link: "https://www.instagram.com/dafyonline/",
  },
  {
    id: "3",
    logo: <YouTubeIcon sx={{ fontSize: "30px" }} />,
    link: "https://www.youtube.com/channel/UCaDXn5tA7A4-pX1srS1fJ6A",
  },
  {
    id: "4",
    logo: (
      <img
        src={twitter}
        alt="twitter Logo"
        style={{ width: "30px", objectFit: "contain", padding: "5px" }}
      />
    ),
    link: "https://twitter.com/dafyindia",
  },
  {
    id: "5",
    logo: <WhatsAppIcon sx={{ fontSize: "30px" }} />,
    link: "https://wa.me/7592933933",
  },
  {
    id: "6",
    logo: <LinkedInIcon sx={{ fontSize: "30px" }} />,
    link: "https://www.linkedin.com/company/dafyonline/",
  },
];

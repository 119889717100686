import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Fab,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePDF from "./Components/InvoicePDF";
import Loader from "./Components/Loader";
import DafyDetails from "./Components/PreviewComponents/DafyDetails";
import DetailTable from "./Components/PreviewComponents/DetailTable";
import TripDetails from "./Components/PreviewComponents/TripDetails";
import DriverDetail from "./Components/PreviewComponents/DriverDetail";
import FareSummary from "./Components/PreviewComponents/FareSummary";
import InfoText from "./Components/PreviewComponents/InfoText";

const A4Container = styled(Box)(({ theme }) => ({
  width: "210mm",
  backgroundColor: "#fff",
  padding: "1rem",
  fontFamily: "Arial, sans-serif",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0.5rem",
  },
  alignSelf: "center",
}));

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery("(max-width: 1024px)");
  const getTripData = useCallback(async () => {
    try {
      const pathSegments = location.pathname.split("/");
      const tripID = pathSegments[pathSegments.length - 1];
      if (tripID) {
        setLoading(true);
        const encodedTripID = encodeURIComponent(tripID);
        const url = `https://staging.api.dafy.in/invoice?tripID=${encodedTripID}`;
        const response = await axios.get(url);
        if (response?.data && response?.data?.data) {
          setInvoiceData(response?.data?.data);
        } else {
          setError("No data received from server");
        }
      } else {
        setError("Invalid URL parameters");
      }
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    getTripData();
  }, [getTripData]);

  return loading ? (
    <Loader />
  ) : (
    <Stack
      sx={{
        padding: { xs: "0.7rem", lg: "1rem" },
        backgroundColor: "beige",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          marginBottom: "1rem",
        }}
      >
        Trip Invoice
      </Typography>

      <A4Container>
        <DafyDetails title={""} />
        <TripDetails invoiceData={invoiceData} />
        <DetailTable invoiceData={invoiceData} />
        <FareSummary invoiceData={invoiceData} />
        <DriverDetail invoiceData={invoiceData} />
        <InfoText />
      </A4Container>
      <PDFDownloadLink
        document={<InvoicePDF invoiceData={invoiceData} />}
        fileName={`Dafy_Invoice_${moment().format("DD-MM-YYYY")}`}
        style={{
          width: "fit-content",
          position: "absolute",
          bottom: isMobileOrTablet ? "5rem" : "3rem",
          right: isMobileOrTablet ? "2rem" : "5rem",
          alignSelf: "flex-end",
          textDecoration: "none",
        }}
      >
        {({ loading, error }) => {
          if (!loading) {
            return (
              <Fab
                variant="extended"
                sx={{ fontWeight: 700, bottom: 0 }}
                color="primary"
              >
                <DownloadIcon sx={{ mr: !isMobileOrTablet ? 1 : 0 }} />
                {!isMobileOrTablet && "Download"}
              </Fab>
            );
          }
          return null;
        }}
      </PDFDownloadLink>
    </Stack>
  );
};

export default Invoice;

const styles = {
  body: {
    width: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    
  },
  boxContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    py: "2rem",

  },
  boxLogoContainer: {
    width:"100%",
    height:"100%",
    px: "20px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    
  },
  boxItems: {
    width:"100%",
    height:'100%',
    px: "20px",
    display:'flex',
    flexDirection:'column',
    alignItems:{
      xs : 'center',
      sm :'center',
      md :'center',
      lg :'flex-start',
      xl:'flex-start'
    }
    
  },
  copyRightsContainer: {
    width: "100%",
    py: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default styles;

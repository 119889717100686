import React, { useState } from "react";
import "./index.css";
// Importing Mui Elements
import { Drawer, Link, Typography } from "@mui/material";
import Logo from "../../Assets/dafy-logo.png";
import { SocialMedia } from "../../Constants/Footer";

// Importing Theme From Theme
import { tokens } from "../../theme";
import { Box } from "@mui/material";

import { DateRange, TimeToLeave } from "@mui/icons-material";
import FooterItem from "../FooterItem";

//Importing Icons
import CallIcon from "@mui/icons-material/Call";
import { isMobile } from "react-device-detect";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

function NavBar({ scrolling }) {
  const [modal, setModal] = useState(false);
  // NavLinks ------------------------------------------------------
  const NavLinks1 = [
    {
      id: "1",
      link: "Home",
    },
    {
      id: "2",
      link: "Service",
    },
    {
      id: "3",
      link: "About-Us",
    },
    {
      id: "4",
      link: "Team",
    },
    {
      id: "5",
      link: "Testimonials",
    },
    {
      id: "6",
      link: "Contact",
    },
  ];

  return (
    <>
      {modal && (
        <Drawer
          anchor="right"
          open={modal}
          onClose={() => setModal(false)}
          variant="persistant"
        >
          <Box
            sx={{
              height: "100%",
              width: 300,
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "60px", height: "60px" }}
              />
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent="flex-start"
              width={"100%"}
            >
              {NavLinks1.map((item) => (
                <>
                  <Link
                    href={`#${item.link}`}
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      paddingTop: "10px",
                      paddingLeft: "2rem",
                    }}
                    underline="false"
                    color={"secondary"}
                    onClick={() => setModal(false)}
                  >
                    {item.link}
                  </Link>
                </>
              ))}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",

                padding: "2rem",
              }}
            >
              <Typography variant="subtitle1">BUSINESS TIME</Typography>
              <FooterItem
                item={{
                  id: "1",
                  logo: <DateRange />,
                  link: "Monday - Sunday",
                }}
              />
              <FooterItem
                item={{
                  id: "2",
                  logo: <TimeToLeave />,
                  link: "Any Time",
                }}
              />

              <Box
                display={"flex"}
                justifyContent={{
                  xs: "space-evenly",
                  sm: "space-evenly",
                  md: "space-evenly",
                  lg: "space-evenly",
                  xl: "space-evenly",
                }}
                width={{
                  xs: "100%",
                  sm: "100%",
                  md: "auto",
                  lg: "auto",
                  xl: "auto",
                }}
                py={"1rem"}
              >
                {SocialMedia.map((item) => (
                  <Link
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    color={"secondary"}
                    paddingRight={"10px"}
                  >
                    {item.logo}
                  </Link>
                ))}
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
      {isMobile ? (
        <MenuRoundedIcon
          raised
          color="primary"
          sx={{
            position: "absolute",
            fontSize: "3rem",
            backgroundColor: "white",
            borderRadius: "50%",
            padding: ".5rem",
            right: "2rem",
            top: "8rem",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 15px",
          }}
          onClick={() => setModal(true)}
        />
      ) : (
        <Box
          className={`navbar-container ${scrolling ? "navbar-scroll" : ""}`}
          backgroundColor={tokens?.primary[500]}
          sx={{
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
            height: {
              xs: "55px",
              sm: "55px",
              md: "65px",
              lg: "70px",
              xl: "70px",
            },
          }}
        >
          <Box
            className="navbar-left-container"
            backgroundColor={tokens?.primary[500]}
            style={{
              borderRadius: scrolling ? "0" : "50px",
              width: {
                sm: "85%",
                md: "75%",
                lg: "75%",
                xl: "75%",
              },
            }}
          >
            <Box
              className="nav-links"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              {NavLinks1.map((item) => {
                return (
                  <Link
                    key={item.id}
                    href={`#${item.link}`}
                    color={"secondary"}
                    underline="false"
                    sx={{
                      fontSize: {
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1.1rem",
                      },
                      fontWeight: "550",
                      letterSpacing: "2px",
                    }}
                  >
                    {item.link}
                  </Link>
                );
              })}
            </Box>
          </Box>
          <Box
            className="navbar-right-container"
            backgroundColor={tokens?.secondary[500]}
            style={{
              borderTopRightRadius: scrolling ? "0" : "50px",
              borderBottomRightRadius: scrolling ? "0" : "50px",
              width: {
                sm: "15%",
                md: "25%",
                lg: "25%",
                xl: "25%",
              }, // Adjust the border radius
            }}
          >
            <Typography
              variant="body1"
              color={tokens?.primary[500]}
              sx={{
                fontSize: {
                  sm: "0.8rem",
                  md: "1rem",
                  lg: "1.1rem",
                  xl: "1.2rem",
                },
              }}
            >
              +91 7592933933
            </Typography>
            <Typography
              variant="body3"
              color={tokens?.primary[500]}
              sx={{
                fontSize: {
                  sm: "0.8rem",
                  md: "1rem",
                  lg: "1.1rem",
                  xl: "1.2rem",
                },
              }}
            >
              PHONE LINE
            </Typography>
            <Box
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: "-27.5px",
              }}
            >
              <Box
                className="navbar-icon-box"
                // sx={{
                //   display: { xs: "none", md: "none", lg: "flex", xl: "flex" },
                //   top: { xs: "0", sm: "", md: "", lg: "0.5rem", xl: "0.8rem" },
                //   right: { xs: "", sm: "", md: "", lg: "22%", xl: "23%" },
                // }}
              >
                <CallIcon color="primary" sx={{ fontSize: "30px" }} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default NavBar;

import { Stack, TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "./styles";

function DetailTable({ invoiceData }) {
  const [data, setData] = useState({ items: [], subAmount: 0, gst: 0 });
  useEffect(() => {
    const mData = [];
    if (invoiceData.fareBreakDown?.baseFare) {
      mData.push({
        name: "Base Fare",
        amount: (invoiceData.fareBreakDown.baseFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.distanceFare) {
      mData.push({
        name: "Distance Fare",
        amount: (invoiceData.fareBreakDown.distanceFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.timeFare) {
      mData.push({
        name: "Time Fare",
        amount: (invoiceData?.fareBreakDown?.timeFare || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.driverConvenienceFee) {
      mData.push({
        name: "Driver Convenience Fee",
        amount: (invoiceData?.fareBreakDown?.driverConvenienceFee || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.nightCharge) {
      mData.push({
        name: "Night Fare",
        amount: (invoiceData?.fareBreakDown?.nightCharge || 0) / 100,
      });
    }
    if (invoiceData.fareBreakDown?.discountAmount) {
      mData.push({
        name: "Discount",
        amount: (invoiceData?.fareBreakDown?.discountAmount || 0) / 100,
      });
    }

    const gst = mData.reduce((total, item) => total + item.gst, 0);
    const subAmount = mData.reduce((total, item) => total + item.amount, 0);

    setData({ items: mData, subAmount, gst });
  }, [invoiceData]);

  return (
    <Stack
      sx={{
        border: "1px solid black",
        borderBottomWidth: 0,
      }}
    >
      {data.items.map((item) => (
        <Stack key={item.name} direction={"row"}>
          <TableCell
            align="left"
            sx={{ ...styles.bodyCell, borderBottomWidth: 0, flex: 1 }}
          >
            {item.name}
          </TableCell>
          <TableCell sx={{ ...styles.bodyCell, borderBottomWidth: 0 }}>
            {item.amount ? `₹ ${item.amount.toFixed(2)}` : ""}
          </TableCell>
        </Stack>
      ))}
    </Stack>
  );
}

export default DetailTable;

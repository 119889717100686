import React from "react";
import "./index.css";
import { Box, Typography, Button, useTheme, Link } from "@mui/material";

import MainLayOut from "../../Components/MainLayOut.jsx";

import PersonPinIcon from '@mui/icons-material/PersonPin';
function ForDrivers() {
  const theme = useTheme();
  return (
    <MainLayOut
      title="For Drivers"
      subtitle="Want to Work With us?"
     
    >
      <Box
        className="driver-wrapper"
        display={{
          xs: "block",
          sm: "block",
          md: "flex",
          lg: "flex",
          xl: "flex",
        }}
      >
        <Box
          className="d-left-cntd"
          backgroundColor={theme.palette.blue[500]}
          width={{ xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }}
          height={{ xs: "50vh", sm: "60vh", md: "93vh", lg: "93vh", xl: "93vh" }}
        >
          <Box
            position={"absolute"}
            width={"100%"}
            height={"100%"}
            top={"0"}
            backgroundColor={"rgba(0,0,0,0.6)"}
          ></Box>
          <Typography
          
            variant="subtitle1"
            color={"primary"}
            textAlign={"center"}
            px={"1rem"}
            zIndex={"1"}
            sx={{
              fontSize: {
                xs: "0.6rem",
                sm: ".6rem",
                md: ".9rem",
                lg: "1rem",
                xl: "1.1rem",
              },
              letterSpacing: {
                xs: "2px",
                sm: "3px",
                md: "4px",
                lg: "5px",
                xl: "5px",
              },
            }}
          >
            Are you an experienced driver with a passion for providing
            exceptional service?
            <br /> Join our team as a dedicated driver. We're looking for
            professionals like you to join us!
          </Typography>
          <Link href="tel:+91 7592933933" >
          <Button variant="outlined" size="medium" color="primary" startIcon={<PersonPinIcon sx={{fontSize:"40px"}}/>}>
            Become a Driver
          </Button>
          </Link>
         
        </Box>
      </Box>
    </MainLayOut>
  );
}

export default ForDrivers;

import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export default function MainLayOut({
  children,
  title,
  subtitle,
  subTitleColor = "#000",
  titleColor = "primary",
  bgrColor,
  id,
  compHeight,
}) {
  return (
    <Paper
      id={id}
      data-aos="fade-up"
      data-aos-duration="1000"
      sx={{
        height: compHeight,
        paddingTop: "2rem",
        backgroundColor: bgrColor,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflowX: "hidden",
        boxShadow: "none",
      }}
    >
      <Typography
        variant="subtitle2"
        color={titleColor}
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "0.7rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1.2rem",
          },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        color={subTitleColor}
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.8rem",
            md: "2.5rem",
            lg: "3rem",
            xl: "4rem",
          },
        }}
      >
        {subtitle}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          paddingTop: "2rem",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {children}
      </Box>
    </Paper>
  );
}

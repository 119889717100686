import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./styles";
const LOGO = require("../../../Assets/dafy-logo.png");

function DafyDetailsPDF({ title }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        padding: 10,
        borderBottom: "1px solid black",
      }}
    >
      <View style={styles.flexOne}>
        <Image src={LOGO} style={styles.logo} alt="Loading..." />
        <Text style={styles.companyInfo}>Dafy Online Pvt Limited</Text>
        <Text style={styles.addressText}>1, 34/192 C, Melthara Lane</Text>
        <Text style={styles.addressText}>Padivattom, Edappally</Text>
        <Text style={styles.addressText}>ERNAKULAM Kerala 682024</Text>
        <Text style={styles.addressText}>India</Text>
        <Text style={styles.addressText}>GSTIN 32AAJCD3952G1ZS</Text>
        <Text style={styles.addressText}>+91 7592933933</Text>
        <Text style={styles.addressText}>www.dafy.in</Text>
      </View>
      <View
        style={{
          flex: 1,
          marginRight: "10px",
        }}
      >
        <Text>{title}</Text>
      </View>
    </View>
  );
}

export default DafyDetailsPDF;

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  additionalText: {
    fontSize: 10,
    fontWeight: 400,
    marginLeft: 4,
    marginTop: 4,
  },
  container: {
    border: "1px solid black",
    borderBottomWidth: 0,
    padding: "8px", // Adjust padding according to your design
  },
});
function InfoTextPDF() {
  return (
    <View
      style={{
        borderTop: "1px solid #000",
        borderBottom: 0,
        paddingVertical: 2,
      }}
    >
      <Text style={styles.additionalText}>
        Fares are inclusive of GST. You can download the tax invoice from the
        trip details page for a tax breakdown.
      </Text>
    </View>
  );
}

export default InfoTextPDF;

export const CUSTOMER_REVIEWS = [
  {
    id: 1,
    name: "Jayesh K",
    review:
      "I recently had the pleasure of using the professional driver assistant service provided by DAFY for my family.One aspect that stood out to me was the professionalism and expertise of the driver provided by DAFY.He was not only skilled behind the wheel but also displayed a warm and friendly approach towards us. We felt safe on the road, and the driver consistently exhibited the highest standards of professionalism and respect.I would not hesitate to engage their services again in the future.",
  },
  {
    id: 2,
    name: "Sajesh V",
    review:
      "Using the driver service was a truly amazing experience for me.It blew me away with its excellent service, The driver was attentive and professional, ensuring that I had a comfortable and safe journey. I was truly impressed with the level of service and would definitely use it again.",
  },{
    id : 3 , 
    name : "Archit M D",
    review : "I've been using DAFY's driver / caretaker service for several months now, and I must say they have exceeded my expectations in every way!Considering the exceptional service and professionalism they provide; their charges are more than fair. They strike a perfect balance between affordability and quality, making them a top choice in the industry. I highly recommend DAFY for anyone seeking a driver/caretaker service. Their drivers are polite, friendly, and go the extra mile to ensure customer satisfaction. With their availability, punctuality, reasonable prices, and commitment to safety,      DAFY has set a new standard in the industry. I am grateful for their outstanding service and will continue relying on them for all my transportation needs."
  },
  {
    id : 4 , 
    name : "Nancy Jacob",
    review : "I recently had the opportunity to avail myself the service provided by DAFY,and I am pleased to share my experience. One aspect that impressed me was their responsiveness.Whenever I needed assistance or had a question, their customer service team was readily available and provided prompt and helpful responses.Their efficiency in handling these matters was truly commendable.I highly recommend DAFY's service based on my positive experience. I am grateful for their assistance,and would not hesitate to utilize their services again if needed in the future."
  },
  {
    id : 5 , 
    name : "Anandhu S",
    review : "The efficiency and punctuality of the drivers provided byDAFY had impressed me a lot. Whenever I needed a driver to take me and my friends to  various lounge and venues, they were always prompt and ready to assist. Their knowledge of  the local area and the best routes ensured that we reached our destinations without any hassle.  The drivers were polite, courteous, and made us feel safe throughout the journey."
  },{
    id : 6,
    name : "Sujith E R ",
    review : "I recently had the opportunity to avail myself the service provided by DAFY,and I am pleased to share my experience. One aspect that impressed me was their responsiveness.Whenever I needed assistance or had a question, their customer service team was readily available and provided prompt and helpful responses. Their efficiency in handling these matters was truly commendable. I highly recommend DAFY's service based on my positive experience. I am grateful for their assistance and would not hesitate to utilize their services again if needed in the future."
  }
];

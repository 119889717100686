export const DRIVER_TOS = [
  {
    title: "Service Description",
    items: [
      "The company operates exclusively through an online platform, offering services aimed at providing convenience and ease to its users. Among its primary offerings is the provision of professional drivers for seamless journeys, ensuring timely arrival at destinations. The services provided include one-way trips, round trips, and hospital assistance.",
    ],
  },
  {
    title: "User Eligibility",
    items: [
      {
        subheading: "Valid Driver's License",
        content:
          "Possession of a valid driver's license relevant to the type of vehicle being operated.",
      },
      {
        subheading: "Minimum Age Requirement",
        content:
          "Drivers must be at least 21 to 25 years old, depending on local regulations and company policies.",
      },
      {
        subheading: "Clean Driving Record",
        content:
          "A clean driving record without major traffic violations, DUI convictions, or accidents.",
      },
      {
        subheading: "Background Check",
        content:
          "Successful completion of a background check to ensure a clean criminal record.",
      },
      {
        subheading: "Vehicle Requirements",
        content:
          "If using a personal vehicle, it must meet standards for age, condition, insurance coverage, and registration.",
      },
      {
        subheading: "Medical Fitness",
        content:
          "Meeting medical requirements to ensure physical fitness for driving responsibilities.",
      },
      {
        subheading: "Language Proficiency",
        content:
          "Proficiency in the local language for effective communication with passengers and navigation.",
      },
      {
        subheading: "Customer Service Skills",
        content:
          "Basic customer service skills for courteous and professional interaction with passengers.",
      },
      {
        subheading: "Vehicle Inspection",
        content:
          "Compliance with vehicle inspection requirements to ensure safety and maintenance standards are met.",
      },
      {
        subheading: "Driving Experience",
        content:
          "Having a certain amount of driving experience may be preferred.",
      },
      {
        subheading: "Transportation Background",
        content:
          "Previous experience in transportation-related roles can be advantageous but is not always required.",
      },
    ],
  },
  {
    title: "Driver Responsibilities",
    items: [
      "The Employee is expected to carry his/her valid Driver’s License and other necessary documents whenever they are driving the vehicle for the Company business and is also expected to drive responsibly and safely",
      "Before the trip, the Employee should inspect the vehicle for damage at the time of pickup or before leaving from the assigned task and ensure that no damage is made to the vehicle during the course of business",
      "The Employee should never drink or be under the influence of any substances and drive during the course of Company business. Under no circumstances will alcohol be consumed in the vehicle. The Employee must be responsible for informing passengers of the vehicle alcohol policy and for ensuring the policy is enforced.",
      "The Employee should make every effort to avoid any damage to the vehicle, even scratches, since the Company will be charged a deductible for repairs related to each incident.",
      "Employees should follow the daily working policies and leave policies the Company has provided. The minimum working days are mandatory to follow the 70-30 criterion which is a minimum of 25 days (full-time) of availability in the office and for drives",
    ],
  },
  {
    title: "Navigation and Routing",
    items: [
      "The Employees are expected to map out driving routes ahead of time to determine the most expedient trip and are also supposed to pick up clients from the place at the time they’ve requested.",
    ],
  },
  {
    title: "Customer Interaction",
    items: [
      "Employee should follow the daily mandatory arrangements before taking the ride, which include, wearing a uniform and identity Card, being properly groomed, and after the ride the duty of the collection of proper payment and the settlement of the particular ride to Company without any malpractices.",
      "The Employee should be aware of the traffic and weather reports to stay up to date on road conditions so that he/she can adjust the route to avoid heavy traffic or road constructions, as needed",
    ],
  },
  {
    title: "Liability and Disclaimers",
    items: [
      "The Employee is expected to take enough care of the vehicle, during business, as if it were their own and should realize that they will be responsible for repair costs, whatsoever, resulting from any negligence arising on the Employees’ part.",
      "Employees should follow the daily working policies and leave policies the Company has provided. The minimum working days are mandatory to follow the 70-30 criterion which is a minimum of 25 days (full-time) of availability in the office and for drives.",
      "Necessary actions will be taken, if the above policies are not maintained or any unnecessary extension of the leaves other than the policy limit, may lead to cancellation of the 70-30 policy for a particular driver, and a pay cut or wage reduction would be imposed according to what the Company feels depending upon the circumstance.",
      "Malpractices in the collection of the amount from the customer would lead to the payment of certain penalties (Monthly Salary/Particular ride cost/ Customer loss cost) to the Company. The penalty will be directly proportional to the loss incurred by the Company due to the committed malpractice and the Company has the sole discretion in taking further steps towards an amicable resolution of the problem.",
      "The Employee should be solely responsible for all the damage that arises due to negligence and any injury that happens because of the Employee’s fault during the course of business. The Company won’t, in any way, be responsible for any damages or injuries that arise due to the Employee’s lack of responsibility.",
      "The complaints registered against the drivers either in the Complaint Redressal platform or of any sort communicated to the Company would be taken very seriously and strict actions would be taken against such employees in such scenarios which may either involve the payment of fine or other remedies that the Company finds suitable for the situation. If more than three complaints are registered against an Employee, serious action would be taken, which may either result in termination of the employment or any legal remedies can be initiated against such employee if required.",
      "Any legal consequences that arise due to the Employee’s lack of responsibility should be borne by the Employee itself and the Company will not be having any liability with respect to such circumstances.",
      "This Agreement will be governed by and will be interpreted in accordance with the laws of the State of Kerala, under Ernakulam jurisdiction. The applicable jurisdiction will be the one from the city where this agreement is signed. Any controversy or difference related to this Agreement will be resolved by ordinary justice.",
    ],
  },
  {
    title: "Termination of Service",
    items: [
      "The Company has the right to immediately terminate the Agreement if the Employee commits any breach or fails to adhere to the terms of this Agreement.",
      "Employee has the right to terminate this Agreement by providing a one-month prior written notice to the other. ",
    ],
  },
];
